import { EventEmitter } from '@angular/core';
import { EtapaABR } from './../../entities/etapas';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ToastType } from 'src/app/entities/toast';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { FinalizandoCertificacaoService } from 'src/app/services/finalizando-certificacao.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'modal-emitir-numero-certificacao',
  templateUrl: './modal-emitir-numero-certificacao.component.html',
  styleUrls: ['./modal-emitir-numero-certificacao.component.scss']
})
export class ModalEmitirNumeroCertificacaoComponent implements OnInit {

  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Output() certificadoDefinido = new EventEmitter();

  certificado = ''

  constructor(
    private finalizaCertificacaoService: FinalizandoCertificacaoService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(numeroCertificacao: HTMLInputElement, modal: any) {
    if (!numeroCertificacao.value.length || !this.preencherCertificado) {
      return;
    }

    this.finalizaCertificacaoService.respostaFormularioABR(this.unidadeProdutiva, numeroCertificacao.value)
      .subscribe(res => {
        this.toastService.showToast(ToastType.SUCCESS, 'Certificação ABR', 'Certificação ABR definida!');
        this.certificadoDefinido.emit();
        modal.close();
      });
  }

  get preencherCertificado(): boolean {
    return this.unidadeProdutiva.etapaAbr === EtapaABR.CERTIFICACAO_EM_EMISSAO 
    || this.unidadeProdutiva.etapaAbr === EtapaABR.VCP_CONCLUIDA;  
  }

}
