import { HttpParams } from "@angular/common/http";
import { Services } from "./service";
import { HistoricoCertificacao } from "src/app/entities/historicoCertificacao";
import { delay, shareReplay } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Status, StatusPcnc } from "src/app/entities/status";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";

import { Injectable } from "@angular/core";

import { EtapaABR } from "../entities/etapas";
import { Safra } from "../entities/safra";
import { AppService } from "./app-service";
import { IFormularioService } from "./IFormulatio";

@Injectable({
  providedIn: "root",
})
export class PcncService implements IFormularioService {
  constructor(private service: Services, private appService: AppService) {}

  getStatus(unidadeProdutiva: UnidadeProdutiva, safra: Safra) : Observable<StatusPcnc> {
    return this.appService.formatarStatusPcnc(
      this.service.get(`EtapaPcnc/${unidadeProdutiva.id}/${safra.id}/status`)
    );
  }

  getStatusSafraSelecionada(
    unindadeProdutiva: UnidadeProdutiva
  ): Observable<StatusPcnc> {
    const safra = this.appService.getSafraSelecionada();

    return this.getStatus(unindadeProdutiva, safra);
  }

  getStatusEnvioEmail(unidadeProdutiva: UnidadeProdutiva, safra: Safra) : Observable<StatusPcnc> {
    return this.appService.formatarStatusPcnc(
      this.service.get(`EtapaPcnc/${unidadeProdutiva.id}/${safra.id}/statusEnvioEmail`)
    );
  }

  getStatusSafraSelecionadaEnvioEmail(
    unindadeProdutiva: UnidadeProdutiva
  ): Observable<StatusPcnc> {
    const safra = this.appService.getSafraSelecionada();

    return this.getStatusEnvioEmail(unindadeProdutiva, safra);
  }

  concluir(unidadeProdutiva: UnidadeProdutiva) {
    return this.service.patch(`EtapaPcnc/${unidadeProdutiva.id}/concluirPcnc`);
  }

  reenviarEmail(unidadeProdutiva: UnidadeProdutiva): Observable<{ message: string }> {
    return this.service.post(`EtapaPcnc/${unidadeProdutiva.id}/reenviaEmailPcnc`, {});
  }

  buscarFormulario(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    const safra = this.appService.getSafraSelecionada();
    return this.service.patch(`EtapaPcnc/${unidadeProdutiva.id}/${safra.id}/buscarFormulario`);
  }

  pcncRecusouVCP(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    return this.service.patch(`EtapaPcnc/${unidadeProdutiva.id}/PCNCRecusouVCP`);
  }

  pularPCNC(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    return this.service.patch(`EtapaPcnc/${unidadeProdutiva.id}/pulaEtapaPcnc`);
  }
}
