import { HomeComponent } from './../home/home.component';
import { Pipe, PipeTransform } from '@angular/core';
import { SetoresHome } from '../entities/setores-home';


@Pipe({
  name: 'podeVerSetorPipe'
})
export class PodeVerSetorPipe implements PipeTransform {

  constructor(
    private homeComponent: HomeComponent
    ) { }

  transform(setor: SetoresHome, ...args: unknown[]): boolean {
    return this.homeComponent.podeVerSetor(setor);
  }

}
