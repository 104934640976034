<a href="#" aria-pressed="true" (click)="open(content)">Editar
    dados</a>

<ng-template #content let-modal>
    <div class="col-lg-12">
    <div class="modal-body">
        <h6 class="card-title">Editar usuário - Responsável ABR</h6><br>
        <label for="cpf" class="col-form-label col-form-label-sm">CPF/Login:</label><br>
        <label for="">{{usuarioConvidado.cpf}}</label><br>
        <form [formGroup]="formConviteEditar">            
            <div class="form-group">
                <label for="nomeCompleto" class="col-form-label col-form-label-sm">Nome completo*</label>
                <input formControlName="nome" class="form-control form-control-sm col-lg-12" [ngClass]="{'is-invalid': formConviteEditar.get('nome').errors && formConviteEditar.get('nome').touched}" type="text" id="nomeCompleto"/>
                <div id="campoObrigatorio" class="invalid-feedback">
                    Campo obrigatório.
                </div>
            </div>
            <div class="form-group">
                <label for="email" class="col-form-label col-form-label-sm">E-mail*</label>
                <input formControlName="email" class="form-control form-control-sm col-lg-12" [ngClass]="{'is-invalid': formConviteEditar.get('email').errors && formConviteEditar.get('email').touched}" type="email" id="email"/>
                <div id="campoObrigatorio" class="invalid-feedback">
                    Campo obrigatório.
                </div>
            </div>
            <div class="form-group">
                <label for="telefoneFixo" class="col-form-label col-form-label-sm">Telefone fixo*</label>
                <input formControlName="telefoneFixo" class="form-control form-control-sm col-lg-12" [ngClass]="{'is-invalid': formConviteEditar.get('telefoneFixo').errors && formConviteEditar.get('telefoneFixo').touched}" type="text" id="telefoneFixo"/>
                <div id="campoObrigatorio" class="invalid-feedback">
                    Campo obrigatório.
                </div>
            </div>
            <div class="form-group">
                <label for="celular" class="col-form-label col-form-label-sm">Celular*</label>
                <input formControlName="celular" class="form-control form-control-sm col-lg-12" [ngClass]="{'is-invalid': formConviteEditar.get('celular').errors && formConviteEditar.get('celular').touched}" type="text" id="celular"/>
                <div id="campoObrigatorio" class="invalid-feedback">
                    Campo obrigatório.
                </div>
            </div>
        </form>        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="editar()">Salvar</button>
    </div>
</div>
</ng-template>