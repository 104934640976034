import { AppService } from './app-service';
import { Services } from './service';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Etapas } from '../entities/etapas';
import { UnidadeProdutiva } from '../entities/unidade-produtiva';
import { Safra } from '../entities/safra';


@Injectable({
  providedIn: 'root'
})
export class EtapaService {

  constructor(private services: Services, private appServices: AppService) { }

  getOpcoesGerenciarEtapas(idUnidadeProdutiva: number): Observable<Etapas[]> {
    const {id} = this.appServices.getSafraSelecionada();
    return this.services.get(`Etapas/${idUnidadeProdutiva}/${id}/getOpcoesGerenciarEtapas`);

  }

  getCertificadoABR(unidadeProdutiva: UnidadeProdutiva, safra: Safra) {
    return this.services.get(`Etapas/${unidadeProdutiva.id}/${safra.id}/certificadoABR`);
  }

  getCertificadoABRSafraSelecionada(unidadeProdutiva: UnidadeProdutiva) {
    const safra = this.appServices.getSafraSelecionada();
    return this.getCertificadoABR(unidadeProdutiva, safra);
  }

  getCertificadoBCI(unidadeProdutiva: UnidadeProdutiva, safra: Safra) {
    return this.services.get(`Etapas/${unidadeProdutiva.id}/${safra.id}/certificadoBCI`);
  }

  getCertificadoBCISafraSelecionada(unidadeProdutiva: UnidadeProdutiva) {
    const safra = this.appServices.getSafraSelecionada();
    return this.getCertificadoBCI(unidadeProdutiva, safra);
  }

  getEtapas() {
    return this.services.get(`Etapas/getEtapas`);
  }

  getNivelEtapas() {
    return this.services.get(`Etapas/getNivelEtapas`);
  }

  trocarEtapa(unidadeProdutivaId: number, etapaId: string): Observable<void>{
    const {id} = this.appServices.getSafraSelecionada();
    return this.services.postWithParams(`Etapas/${unidadeProdutivaId}/${id}/postGerenciarEtapas`,
    {EtapaAbrId: etapaId});
  }
}
