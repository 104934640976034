<div class="row mb-lg-4">
  <div class="col-12 col-lg-3">
    <titulo-modal>Ações</titulo-modal>
    <ng-container *ngIf="safraDefinida" class="row">
      <modal-definir-safra [unidadeProdutiva]="unidadeProdutiva" (safraDefinida)="onSafraDefinida($event)">
      </modal-definir-safra>
    </ng-container>
    <div class="deadline-alert-container">
      <div class="deadline-alert-label">
        <fa-icon class="exclamation-icon" [icon]="exclamationIcon" size="md"></fa-icon>
        Prazo limite para aceite do licenciamento BCI:
      </div>
      <span *ngIf="!prazoLimiteBci">Não definido</span>
      <span *ngIf="!loadingPrazoLimiteBci; else shimmer">{{ prazoLimiteBci | date:'dd/MM/yyyy' }}</span>
      <ng-template #shimmer>
        <shimmer width="70px"></shimmer>
      </ng-template>
    </div>
  </div>
  <div class="col-lg-9">
    <div class="row">
      <div class="col-sm-6">
        <app-status-convite [loading]="loadingStatus" title="Status do Convite ABR" [status]="statusAbr">
        </app-status-convite>
      </div>
      <div class="col-sm-6">
        <app-status-convite [loading]="loadingStatus" title="Status do Convite BCI" [status]="statusBci">
        </app-status-convite>
      </div>
    </div>
  </div>
</div>
<titulo-modal>Usuários Convidados</titulo-modal>
<app-acesso-convites [unidadeProdutiva]="unidadeProdutiva"></app-acesso-convites>
