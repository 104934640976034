<div class="container-fluid p-3" style="background-color: #fff">
  <div class="mb-4">
    <p class="h4"><strong>
        Nota Explicativa
      </strong></p>
    <p class="text-justify pr-2 pl-2">
      A Nota Explicativa é utilizada em ocasiões em que critérios das listas VDP e VCP da Unidade Produtiva que está
      em
      processo de certificação são verificados em outra Unidade Produtiva que, geralmente, serve de sede para esta.
    </p>
  </div>
  <div class="h5 text-muted">
    <strong>A Nota Explicativa já foi gerada para esta Unidade Produtiva</strong>
  </div>

  <button (click)="downloadNotaExplicativa()" class="btn-download btn btn-sm d-flex">
    Download da Nota Explicativa
  </button>
  <div class="row justify-content-end mr-2">
    <button (click)="close()" class="btn btn-sm btn-light mr-2">Cancelar</button>
  </div>
</div>