export interface Etapas {
  id: number;
  descricao: string;
  nivelEtapaId: number;
}

// TODO: Rever os status
export enum EtapaABR {
  ABR_CERTIFICADO_NAO_OPTANTE_BCI = "Certificada ABR / Não optante BCI",
  ABR_CERTIFICADA = "ABR - Certificado",
  NAO_INICIADO = "Não iniciado",
  NAO_CONVIDADA = "Não Convidada",
  BCI_LICENCIADA = "BCI - Licenciado",
  CERTIFICADO_ABR_BCI_LICENCIADO = "Certificada ABR / Licenciada BCI",
  CONVIDADA = "Convidada",
  CONVITE_ACEITO = "Convite aceito",
  CONVITE_ACEITO_ABR = "Convite aceito - ABR", // status
  CONVITE_ACEITO_BCI = "Convite aceito - BCI", // status
  CONVITE_RECUSADO = "Convite recusado - ABR", // status
  CONVITE_RECUSADO_BCI = "Convite recusado - BCI", // status
  CONVITE_REALIZADO_ABR = "Convite realizado - ABR", // status
  CONVITE_REALIZADO_BCI = "Convite realizado - BCI", // status
  CONVITE_RECUSADO_HOME = "Convite recusado",
  VDP_ANDAMENTO = "VDP - Em andamento",
  VDP_EM_ANDAMENTO_COMPLETA_NAO_CONCLUIDA = "VDP - Em andamento (completo)", // revisar lógica
  VDP_CONCLUIDA = "VDP - Concluída",
  VDP_OPTOU_PULAR_VDP = "VDP - Optou por pular VDP e PCNC",
  PCNC_AUSENTE_DE_NAO_CONFORMIDADES = "PCNC - Ausente de Não Conformidades", //revisar
  PCNC_CONCLUIDO = "PCNC - Concluído",
  PCNC_OPTOU_PULAR_PCNC = "PCNC - Optou por pular PCNC",
  PCNC_EM_ANDAMENTO = "PCNC - Em andamento",
  PCNC_RECUSOU_VCP = "PCNC - Recusou prosseguir para a VCP",
  PCNC_ACEITOU_VCP = "PCNC - Aceitou prosseguir para a VCP",
  VCP_EM_ANDAMENTO_INCOMPLETA = "VCP - Em andamento", //revisar
  VCP_EM_ANDAMENTO_COMPLETA_NAO_CONCLUIDA = "VCP - Em andamento (completo)", //revisar
  VCP_REPROVADA = "VCP - Reprovada",
  VCP_CONCLUIDA = "VCP - Concluída",
  VCP_EM_ANDAMENTO = "VCP - Em andamento",
  VCP_APROVADA = "VCP - Aprovada",
  CERTIFICACAO_CANCELADA = "Certificação Cancelada", // stus
  CERTIFICACAO_EM_EMISSAO = "Certificado em emissão",
  CERTIFICADA_ABR_NAO_OPTANTE_BCI = "Certificada ABR / Não optante BCI",
  CERTIFICADA_ABR_EM_ANDAMENTO_BCI = "Certificada ABR / Em andamento BCI",
  CERTIFICADA_ABR_LICENCIADO_BCI = "Certificada ABR / Licenciada BCI",
  CERTIFICADA_ABR_REPROVADO_BCI = "Certificada ABR / Reprovada BCI",
  CERTIFICADA_ABR_CANCELADA_BCI = "Certificada ABR / Cancelada BCI",
  NAO_OPTANTE_BCI = "Não optante BCI",

  //Reuso para status do convites
  ACEITO = 'aceito'
}
const stringToEtapaABR = <ET, T>(enumObj: ET, str: string): T =>
  (enumObj as any)[Object.keys(enumObj).filter(k => (enumObj as any)[k] === str)[0]];

export const toEtapaAbr = (descricao: string): EtapaABR => stringToEtapaABR<typeof EtapaABR, EtapaABR>(EtapaABR, descricao);
