import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

export interface Step {
  label: string;
  active: boolean;
}

@Component({
  selector: "multi-steps",
  templateUrl: "./multi-steps.component.html",
  styleUrls: ["./multi-steps.component.scss"],
})
export class MultiStepsComponent implements OnInit {
  @Input() activeStepLabel: string;
  @Input() steps: Step[];
  @Input() esconderAndamentoCertificacao: boolean;

  @Output() clickStep = new EventEmitter<Step>();
  @Output() showAndamentoCertificacao = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void { }

  onShowAndamentoCertificacaoClick() {
    this.showAndamentoCertificacao.emit();
  }

  onClickStep(step: Step) {
    if (!step.active) {
      return;
    }
    this.clickStep.emit(step);
  }
}
