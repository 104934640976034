import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/base/base.component';
import { SafraCertificacao } from '../../entities/safrasCertificacao';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import {
  switchMap,
} from "rxjs/operators";
import { EMPTY } from 'rxjs';
import { ToastType } from 'src/app/entities/toast';
@Component({
  selector: 'modal-definir-safra',
  templateUrl: './modal-definir-safra.component.html'
})
export class ModalDefinirSafra extends BaseComponent {
  @Input() safraCertificacaoUp: SafraCertificacao;
  @Input() unidadeProdutiva: UnidadeProdutiva;

  @Output() safraDefinida = new EventEmitter();

  safraCertificacao: FormControl;
  safras: SafraCertificacao[];
  safraSelecionada = false;
  formSafraCertificacao = this.fb.group({
    upId: '',
    safraCertificacao: ''
  });

  open(content) {
    this.safraSelecionada = false;
    this.formSafraCertificacao.controls.upId.setValue(this.unidadeProdutiva.id)
    this.formSafraCertificacao.controls.safraCertificacao.setValue(this.safraCertificacaoUp);
    this.modalService.open(content, { centered: true })
  }

  ngOnInit(): void {
    this.listaSafras();
  }

  listaSafras() {
    this.service.getAll('SafraCertificacaoUps/listaSafrasParaCertificacao').subscribe(x => {
      this.safras = x;
    });
  }

  onSubmit(modal: any, safraCertificacao: any) {
    const safraCertificacaoUp = new SafraCertificacao();
    const usuario = this.appService.getUsuario();
    safraCertificacaoUp.unidadeProdutivaId = this.unidadeProdutiva.id;
    safraCertificacaoUp.safraCertificacao = Number(safraCertificacao.value);
    safraCertificacaoUp.cpfUsuario = usuario.unique_name;
    safraCertificacaoUp.nomeUsuario = usuario.NomeUsuario;
    const result$ = this.alertService.showConfirm();
    result$.asObservable().pipe(
      switchMap(x => {
        if (x) {
          return this.service.post('SafraCertificacaoUps/defineSafraCertificacao', safraCertificacaoUp);
        }
        else {
          return EMPTY;
        }
      })
    ).subscribe(x => {
      modal.close();
      this.safraDefinida.emit(x);
      this.showToast(ToastType.SUCCESS, 'Sucesso', x.message);
      this.ngOnInit();

      this.appModalService.changeSafraCertificacao(x.safraCertificacao);
    });
  }

  onOptionsSelected(input: any) {
    if (input.value) {
      this.safraSelecionada = true;
    }
  }
}
