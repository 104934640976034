import { EventEmitter } from '@angular/core';
import { EtapaABR } from 'src/app/entities/etapas';
import { Component, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { Status } from 'src/app/entities/status';
import { ToastType } from 'src/app/entities/toast';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { AndamentoCertificacaoService } from 'src/app/services/andamento-certificacao.service';
import { GestaoAbrapaService } from 'src/app/services/gestao-abrapa.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ToastService } from 'src/app/services/toast.service';
import { UpCertificacaoService } from 'src/app/services/up-certificacao.service';
import { Step } from 'src/app/shared/multi-steps/multi-steps.component';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';


const statusInicial = {
  etapa: "",
  etapaId: null,
  nome: "",
  dataInclusao: null,
  pulouEtapa: false
};

@Component({
  selector: 'modal-etapa-gestao-convite',
  templateUrl: './modal-etapa-gestao-convite.component.html',
  styleUrls: ['./modal-etapa-gestao-convite.component.scss']
})
export class ModalEtapaGestaoConviteComponent implements OnInit, OnDestroy {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Output() atualizacaoRealizada = new EventEmitter();

  aceitaParticipacao: { aceiteBci: boolean } = {
    aceiteBci: false
  };

  loadingDataLimiteRespostaConviteBci = true;
  loadingStatusConvite = true;
  podeResponderConviteBci = false;
  dataLimiteRespostaConviteBci: Date;
  dataAtual = moment();

  statusConviteAbr: Status = {
    ...statusInicial
  };

  statusConviteBci: Status = {
    ...statusInicial
  };

  constructor(
    private andamentoCertificacaoService: AndamentoCertificacaoService,
    private upCertificacao: UpCertificacaoService,
    private gestaoAbrapaService: GestaoAbrapaService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    this._inicializar();
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  get naoPodeResponderConviteTooltip() {
    return !this.podeResponderConviteBci ?
      "O prazo para responder o convite BCI já foi expirado." : "";
  }

  _inicializar() {
    this.loadingDataLimiteRespostaConviteBci = true;
    this.loadingStatusConvite = true;

    const upSertificacao$ = this.upCertificacao
      .aceitouParticiparBCI(this.unidadeProdutiva.id)
      .subscribe((aceitou) => {
        this.aceitaParticipacao = aceitou;
      });

    const gestaoAbrapa$ = this.gestaoAbrapaService
      .getDataLimiteEditarConviteBci()
      .pipe(
        finalize(() => {
          this.loadingDataLimiteRespostaConviteBci = false;
        })
      )
      .subscribe((data) => {
        this.dataLimiteRespostaConviteBci = new Date(data.dataLimiteBci);        
        
        const dataAtual = new Date().getTime();
        const podeResponderConviteBci = this.dataLimiteRespostaConviteBci.getTime() >= dataAtual        
        
        if (podeResponderConviteBci) {
          this.podeResponderConviteBci = true;
        }
      });

    const andamentoCertificacao$ = this.andamentoCertificacaoService
      .getStatusConvite(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingStatusConvite = false)
      )
      .subscribe((status) => {
        this.statusConviteAbr = status.abr;
        this.statusConviteBci = status.bci;
      });

    this.subscriptionService.add(upSertificacao$);
    this.subscriptionService.add(gestaoAbrapa$);
    this.subscriptionService.add(andamentoCertificacao$);
  }

  onConfirm(modal: NgbActiveModal) {
    this.spinnerService.show();
    this.upCertificacao
      .editarParticipacaoConviteBci(
        this.aceitaParticipacao.aceiteBci,
        this.unidadeProdutiva.id
      )
      .subscribe(() => {
        this.toastService.showToast(
          ToastType.SUCCESS,
          "Sucesso",
          "Editado com sucesso"
        );
        this.spinnerService.hide();
        this._inicializar();
        this.atualizacaoRealizada.emit();
        modal.close();
      });
  }


  get podeEditarResponderBci(): boolean {    
    
    return this.loadingDataLimiteRespostaConviteBci || this.podeResponderConviteBci || this.isConviteRealizadoBci;
  }

  get podeEditarConviteBci(): boolean {
    return this.statusConviteAbr.etapa === EtapaABR.CONVITE_REALIZADO_ABR;
  }

  get isConviteRealizadoABR(): boolean {
    return this.statusConviteAbr.etapa === EtapaABR.CONVITE_REALIZADO_ABR;
  }

  get isConviteRecusadoABR(): boolean {
    return this.statusConviteAbr.etapa === EtapaABR.CONVITE_RECUSADO;
  }

  get isConviteRecusadoBci(): boolean {
    return this.statusConviteAbr.etapa === EtapaABR.CONVITE_RECUSADO_BCI;
  }

  get isConviteRealizadoBci(): boolean {
    return this.statusConviteAbr.etapa === EtapaABR.CONVITE_REALIZADO_BCI
  }

  get isPrazoValido(): boolean {
    //let dataLimite = moment(environment.dataLimiteRespostaConvite);
    let dataLimite = moment(this.dataLimiteRespostaConviteBci)

    return this.dataAtual.isBefore(dataLimite); 
  }

  get verificaStatusEditarBci(): boolean {           
    
    return this.isPrazoValido && ((this.isConviteRecusadoABR || this.isConviteRealizadoABR) || (!this.podeEditarResponderBci));
  }

}
