export type YesNoEmpty = "0" | "1" | "";

export class UnidadeProdutiva {
  id: number;
  nomeUnidadeProdutiva: string;
  municipio: string;
  tipo: string;
  idProdutorGrupo: number;
  produtorGrupo: string;
  uf: string;
  reiniciarCertificacaoSafraABR: boolean;
  habilitada: YesNoEmpty;
  status: string;
  participanteBCI: string;
  codigoSafra: number;
  safraCertificacao: number;
  etapaAbr: string;
  dataUltimaEtapa: Date;
  numeroProcesso: string;
  nomeAntigo: string;
  nomeAntigoGrupoProdutor: string;
  dataVigenciaNome: Date;
  dataVigenciaGrupoProdutor: Date;
}


export interface DetalharUnidadeProdutiva {
  nomeUnidadeProdutiva: string;
  municipio: string;
  tipo: string;
  idProdutorGrupo: number;
  produtorGrupo: string;
  uf: string;
  reiniciarCertificacaoSafraABR: boolean;
  habilitada: string;
  status: string;
  participanteBCI: string;
  beneficiamento: string;
  codigoSafra: number;
  nomeAssociacao: string;
  cep: string;
  endereco: string;
  latitude: number;
  longitude: number;
  bairro: string;
  areaTotal: number;
  areaAlgodao: number;
  areaProtecao: null;
  email: string;
  matriculas: Matricula[];
  unidadeProdutivaAlgodoeiras: UnidadeProdutivaAlgodoeira[];
  id: number;
  nomeAntigo: string;
  nomeAntigoGrupoProdutor: string;
  dataVigenciaNome: Date;
  dataVigenciaGrupoProdutor: Date;
}

export interface Matricula {
  unidadeProdutivaId: number;
  numeroMatricula: string;
  tipo: string;
  uf: string;
  cidade: string;
  nomeCartorio: string;
  areatotal: number;
  idProdutorGrupo: number;
  codigoSafra: number;
  unidadeProdutivaProdutorId: number;
  unidadeProdutivaProdutorMatricula: UnidadeProdutivaProdutorMatricula[];
  id: number;
}

export interface UnidadeProdutivaProdutorMatricula {
  unidadeProdutivaProdutorId: number;
  unidadeProdutivaId: number;
  idProdutorGrupo: number;
  inscricaoEstadual: string;
  nomeProdutorGrupo: string;
  cpfcnpj: string;
  tipoProdutorGrupo: string;
  areaProdutorGrupo: number;
  codigoSafra: number;
  id: number;
}


export interface UnidadeProdutivaAlgodoeira {
  algodoeiraid: number;
  unidadeProdutivaId: number;
  codigoSafra: number;
  possuiAlgodoeira: boolean;
  nomeFantasia: string;
  codigoGS1: string;
  digitoExtensao: number;
  id: number;
}
