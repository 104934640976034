import { Usuario } from './../entities/usuario-convidado';
import { Safra } from "./../entities/safra";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { createStatus, createStatusPcnc, Status, StatusPcnc } from "../entities/status";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AppService {
  observer = new Subject();
  public subscriber$ = this.observer.asObservable();

  emitDate(data) {
    this.observer.next(data);
  }

  getSafraSelecionada(): Safra {
    return JSON.parse(window.localStorage.getItem("safraEscolhida"));
  }

  safraSelecionadaEhVigente(): boolean {
    return this.getSafraSelecionada().status;
  }

  formatarStatus(status$: Observable<any>): Observable<Status> {
    return status$.pipe(
      map(res => createStatus(res.descricao, res.data, res.usuarioCpf, res.pulouEtapa))
    );
  }

  formatarStatusPcnc(status$: Observable<any>): Observable<StatusPcnc> {
    return status$.pipe(
      map(res => createStatusPcnc(res.descricao, res.data, res.usuarioCpf, res.pulouEtapa, res.ausenteNaoConformidade))
    );
  }

  getUsuario(): Usuario {
    return JSON.parse(window.localStorage.getItem("usuario"));
  }
}
