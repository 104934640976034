import { Injectable } from '@angular/core';

import { SetoresAndamentoCertificacao } from "../entities/andamento-certificacao";
import { ModalHome } from '../entities/modal-home';
import { PerfilUsuario } from "../entities/perfil";
import { PermissaoPerfil } from '../entities/permissao-perfil';
import { Setor } from "../entities/setor";
import { SetoresHome } from '../entities/setores-home';

export type Permissoes = SetoresAndamentoCertificacao[] | SetoresHome[] | ModalHome;

@Injectable({
  providedIn: 'root'
})

export class PermissoesService {

  private permissoesPerfis: PermissaoPerfil[] = [
    {
      perfil: PerfilUsuario.ABRAPA,
      setores: {
        home: [
          SetoresHome.FORM_ID,
          SetoresHome.FORM_UNIDADE_PRODUTIVA,
          SetoresHome.FORM_UF,
          SetoresHome.FORM_MUNICIPIO,
          SetoresHome.FORM_TIPO,
          SetoresHome.FORM_PRODUTOR_GRUPO,
          SetoresHome.FORM_STATUS,
          SetoresHome.FORM_HABILITADO,
          SetoresHome.FORM_PARTICIPANTE_BCI,
          SetoresHome.FORM_SAFRA_CERTIFICACAO,
          SetoresHome.FORM_ETAPA_ABR,
          SetoresHome.BTN_DATA_LIMITE,
          SetoresHome.COLUNA_ID,
          SetoresHome.COLUNA_UNIDADE_PRODUTIVA,
          SetoresHome.COLUNA_UF,
          SetoresHome.COLUNA_MUNICIPIO,
          SetoresHome.COLUNA_TIPO,
          SetoresHome.COLUNA_PRODUTOR_GRUPO,
          SetoresHome.COLUNA_STATUS,
          SetoresHome.COLUNA_HABILITADO,
          SetoresHome.COLUNA_SAFRA_CERTIFICACAO,
          SetoresHome.COLUNA_PARTICIPANTE_BCI,
          SetoresHome.COLUNA_ETAPA_ABR,
          SetoresHome.COLUNA_DATA_ULTIMA_ATUALIZACAO
        ],
        modal_home: ModalHome.ABRAPA,
        andamento_certificacao: [
          SetoresAndamentoCertificacao.CONVITES,
          SetoresAndamentoCertificacao.TERMO_ADESAO,
          SetoresAndamentoCertificacao.VDP,
          SetoresAndamentoCertificacao.VDP_BTN_ACESSO,
          SetoresAndamentoCertificacao.PCNC,
          SetoresAndamentoCertificacao.PCNC_BTN_ACESSO,
          SetoresAndamentoCertificacao.VCP,
          SetoresAndamentoCertificacao.VCP_BTN_ACESSO,
          SetoresAndamentoCertificacao.ABR,
          SetoresAndamentoCertificacao.ABR_BTN_ACESSO,
          SetoresAndamentoCertificacao.DOWNLOAD_CERT_ABR,
          SetoresAndamentoCertificacao.BCI,
          SetoresAndamentoCertificacao.DOWNLOAD_LICENCA_BCI
        ]
      }
    },
    {
      perfil: PerfilUsuario.ESTADUAL,
      setores: {
        home: [
          SetoresHome.FORM_ID,
          SetoresHome.FORM_UNIDADE_PRODUTIVA,
          SetoresHome.FORM_MUNICIPIO,
          SetoresHome.FORM_TIPO,
          SetoresHome.FORM_PRODUTOR_GRUPO,
          SetoresHome.FORM_STATUS,
          SetoresHome.FORM_HABILITADO,
          SetoresHome.FORM_PARTICIPANTE_BCI,
          SetoresHome.FORM_SAFRA_CERTIFICACAO,
          SetoresHome.FORM_ETAPA_ABR,
          SetoresHome.BTN_FORMULARIOS,
          SetoresHome.COLUNA_ID,
          SetoresHome.COLUNA_UNIDADE_PRODUTIVA,
          SetoresHome.COLUNA_MUNICIPIO,
          SetoresHome.COLUNA_TIPO,
          SetoresHome.COLUNA_PRODUTOR_GRUPO,
          SetoresHome.COLUNA_STATUS,
          SetoresHome.COLUNA_HABILITADO,
          SetoresHome.COLUNA_SAFRA_CERTIFICACAO,
          SetoresHome.COLUNA_PARTICIPANTE_BCI,
          SetoresHome.COLUNA_ETAPA_ABR,
          SetoresHome.COLUNA_DATA_ULTIMA_ATUALIZACAO
        ],
        modal_home: ModalHome.ESTADUAL,
        andamento_certificacao: [
          SetoresAndamentoCertificacao.CONVITES,
          SetoresAndamentoCertificacao.TERMO_ADESAO,
          SetoresAndamentoCertificacao.VDP,
          SetoresAndamentoCertificacao.VDP_BTN_ACESSO,
          SetoresAndamentoCertificacao.PCNC,
          SetoresAndamentoCertificacao.PCNC_BTN_ACESSO,
          SetoresAndamentoCertificacao.VCP,
          SetoresAndamentoCertificacao.VCP_BTN_ACESSO,
          SetoresAndamentoCertificacao.ABR,
          SetoresAndamentoCertificacao.ABR_BTN_ACESSO,
          SetoresAndamentoCertificacao.DOWNLOAD_CERT_ABR,
          SetoresAndamentoCertificacao.BCI,
          SetoresAndamentoCertificacao.DOWNLOAD_LICENCA_BCI
        ]
      }
    },
    {
      perfil: PerfilUsuario.RESPONSAVEL_ABR,
      setores: {
        home: [
          SetoresHome.FORM_UNIDADE_PRODUTIVA,
          SetoresHome.FORM_UF,
          SetoresHome.FORM_MUNICIPIO,
          SetoresHome.FORM_TIPO,
          SetoresHome.FORM_PRODUTOR_GRUPO,
          SetoresHome.FORM_STATUS,
          SetoresHome.FORM_HABILITADO,
          SetoresHome.FORM_PARTICIPANTE_BCI,
          SetoresHome.FORM_SAFRA_CERTIFICACAO,
          SetoresHome.FORM_ETAPA_ABR,
          SetoresHome.BTN_FORMULARIOS,
          SetoresHome.COLUNA_UNIDADE_PRODUTIVA,
          SetoresHome.COLUNA_UF,
          SetoresHome.COLUNA_MUNICIPIO,
          SetoresHome.COLUNA_TIPO,
          SetoresHome.COLUNA_PRODUTOR_GRUPO,
          SetoresHome.COLUNA_SAFRA_CERTIFICACAO,
          SetoresHome.COLUNA_PARTICIPANTE_BCI,
          SetoresHome.COLUNA_ETAPA_ABR,
          SetoresHome.COLUNA_DATA_ULTIMA_ATUALIZACAO
        ],
        modal_home: ModalHome.RESPONSAVEL_ABR,
        andamento_certificacao: [
          SetoresAndamentoCertificacao.CONVITES,
          SetoresAndamentoCertificacao.TERMO_ADESAO,
          SetoresAndamentoCertificacao.VDP,
          SetoresAndamentoCertificacao.VDP_BTN_ACESSO,
          SetoresAndamentoCertificacao.PCNC,
          SetoresAndamentoCertificacao.PCNC_BTN_ACESSO,
          SetoresAndamentoCertificacao.VCP,
          SetoresAndamentoCertificacao.VCP_BTN_ACESSO,
          SetoresAndamentoCertificacao.ABR,
          SetoresAndamentoCertificacao.ABR_BTN_ACESSO,
          SetoresAndamentoCertificacao.DOWNLOAD_CERT_ABR,
          SetoresAndamentoCertificacao.BCI,
          SetoresAndamentoCertificacao.DOWNLOAD_LICENCA_BCI
        ]
      }
    },
    {
      perfil: PerfilUsuario.BCI,
      setores: {
        home: [
          SetoresHome.FORM_ID,
          SetoresHome.FORM_UNIDADE_PRODUTIVA,
          SetoresHome.FORM_UF,
          SetoresHome.FORM_MUNICIPIO,
          SetoresHome.FORM_TIPO,
          SetoresHome.FORM_PRODUTOR_GRUPO,
          SetoresHome.FORM_STATUS,
          SetoresHome.FORM_HABILITADO,
          SetoresHome.FORM_PARTICIPANTE_BCI,
          SetoresHome.FORM_SAFRA_CERTIFICACAO,
          SetoresHome.FORM_ETAPA_ABR,
          SetoresHome.BTN_FORMULARIOS,
          SetoresHome.COLUNA_ID,
          SetoresHome.COLUNA_UNIDADE_PRODUTIVA,
          SetoresHome.COLUNA_UF,
          SetoresHome.COLUNA_MUNICIPIO,
          SetoresHome.COLUNA_TIPO,
          SetoresHome.COLUNA_PRODUTOR_GRUPO,
          SetoresHome.COLUNA_STATUS,
          SetoresHome.COLUNA_HABILITADO,
          SetoresHome.COLUNA_PARTICIPANTE_BCI,
          SetoresHome.COLUNA_ETAPA_ABR,
          SetoresHome.COLUNA_DATA_ULTIMA_ATUALIZACAO
        ],
        modal_home: ModalHome.BCI,
        andamento_certificacao: []
      }
    },
    {
      perfil: PerfilUsuario.CERTIFICADORA,
      setores: {
        home: [
          SetoresHome.FORM_ID,
          SetoresHome.FORM_UNIDADE_PRODUTIVA,
          SetoresHome.FORM_UF,
          SetoresHome.FORM_MUNICIPIO,
          SetoresHome.FORM_TIPO,
          SetoresHome.FORM_PRODUTOR_GRUPO,
          SetoresHome.FORM_STATUS,
          SetoresHome.FORM_HABILITADO,
          SetoresHome.FORM_PARTICIPANTE_BCI,
          SetoresHome.FORM_SAFRA_CERTIFICACAO,
          SetoresHome.FORM_ETAPA_ABR,
          SetoresHome.BTN_FORMULARIOS,
          SetoresHome.COLUNA_ID,
          SetoresHome.COLUNA_UNIDADE_PRODUTIVA,
          SetoresHome.COLUNA_UF,
          SetoresHome.COLUNA_MUNICIPIO,
          SetoresHome.COLUNA_TIPO,
          SetoresHome.COLUNA_PRODUTOR_GRUPO,
          SetoresHome.COLUNA_STATUS,
          SetoresHome.COLUNA_HABILITADO,
          SetoresHome.COLUNA_SAFRA_CERTIFICACAO,
          SetoresHome.COLUNA_PARTICIPANTE_BCI,
          SetoresHome.COLUNA_ETAPA_ABR,
          SetoresHome.COLUNA_DATA_ULTIMA_ATUALIZACAO
        ],
        modal_home: ModalHome.CERTIFICADORA,
        andamento_certificacao: [
          SetoresAndamentoCertificacao.CONVITES,
          SetoresAndamentoCertificacao.TERMO_ADESAO,
          SetoresAndamentoCertificacao.VDP,
          SetoresAndamentoCertificacao.PCNC,
          SetoresAndamentoCertificacao.BCI,
          SetoresAndamentoCertificacao.VCP,
          SetoresAndamentoCertificacao.VCP_BTN_ACESSO,
          SetoresAndamentoCertificacao.ABR,
          SetoresAndamentoCertificacao.ABR_BTN_ACESSO,
          SetoresAndamentoCertificacao.DOWNLOAD_CERT_ABR
        ]
      }
    },
    {
      perfil: PerfilUsuario.CERTIFICADORA_AUDITOR,
      setores: {
        home: [
          SetoresHome.FORM_ID,
          SetoresHome.FORM_UNIDADE_PRODUTIVA,
          SetoresHome.FORM_UF,
          SetoresHome.FORM_MUNICIPIO,
          SetoresHome.FORM_TIPO,
          SetoresHome.FORM_PRODUTOR_GRUPO,
          SetoresHome.FORM_STATUS,
          SetoresHome.FORM_HABILITADO,
          SetoresHome.FORM_PARTICIPANTE_BCI,
          SetoresHome.FORM_SAFRA_CERTIFICACAO,
          SetoresHome.FORM_ETAPA_ABR,
          SetoresHome.BTN_FORMULARIOS,
          SetoresHome.COLUNA_ID,
          SetoresHome.COLUNA_UNIDADE_PRODUTIVA,
          SetoresHome.COLUNA_UF,
          SetoresHome.COLUNA_MUNICIPIO,
          SetoresHome.COLUNA_TIPO,
          SetoresHome.COLUNA_PRODUTOR_GRUPO,
          SetoresHome.COLUNA_STATUS,
          SetoresHome.COLUNA_HABILITADO,
          SetoresHome.COLUNA_SAFRA_CERTIFICACAO,
          SetoresHome.COLUNA_PARTICIPANTE_BCI,
          SetoresHome.COLUNA_ETAPA_ABR,
          SetoresHome.COLUNA_DATA_ULTIMA_ATUALIZACAO
        ],
        modal_home: ModalHome.CERTIFICADORA,
        andamento_certificacao: [
          SetoresAndamentoCertificacao.VCP,
          SetoresAndamentoCertificacao.VCP_BTN_ACESSO,
        ]
      }
    }
  ];

  constructor() { }

  getPermissoes(perfil: PerfilUsuario, setor: Setor) : Permissoes {
    const permissoesPerfil =
      this.permissoesPerfis
        .find(permissaoPerfil =>
          PerfilUsuario[permissaoPerfil.perfil] === PerfilUsuario[perfil]);

    if(!permissoesPerfil) {
      return [];
    }

    return permissoesPerfil.setores[setor];
  }
}
