import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SolicitacaoRetorno } from "../entities/solicitacao-retorno";
import { Services } from "./service";

@Injectable({
    providedIn: 'root'
})
export class SolicitarRetornoEtapaService {

   
    private _mainRoute = 'api/SolicitarRetornoParaEtapa';

    private _routes = {
        buscarDadosSolicitacao: 'BuscarDadosSolicitacao',
        listarSolicitacoes: 'Listar',
        buscarMotivoParaHistoricoPorStatus: 'BuscarMotivoParaHistorico',
        verificarSeReprovouNoVDB: 'verificarSeReprovouNoVDB',
    }

    constructor(private services: Services) {}

    getById(id: number): Observable<any> {
        return this.services.getById(`${this._mainRoute}`, id);
    }

    getByIdStatusAtual(idStatusAtual: number): Observable<any> {
        return this.services.get(`${this._mainRoute}/${idStatusAtual}/BuscarDadosSolicitacao`);
    }

    post(objeto: SolicitacaoRetorno): Observable<any> {
        return this.services.post(`${this._mainRoute}`, objeto);
    }
    
    buscarDadosSolicitacao(id: number): Observable<any> {
        return this.services.get(`${this._mainRoute}/${id}/${this._routes.buscarDadosSolicitacao}`);
    }

    cancelarSolicitacaoDeRetorno(id: number): Observable<any> {
        return this.services.delete(`${this._mainRoute}`, id);
    }

    listarSolicitacoes(id: number): Observable<any> {
        return this.services.delete(`${this._mainRoute}/${id}/${this._routes.listarSolicitacoes}`, id);
    }

    buscarMotivoParaHistoricoPorStatus(id: number): Observable<any> {
        return this.services.delete(`${this._mainRoute}/${id}/${this._routes.buscarMotivoParaHistoricoPorStatus}`, id);
    }

    verificarSeReprovouNoVDB(id: number): Observable<any> {
        return this.services.get(`${this._mainRoute}/${id}/${this._routes.verificarSeReprovouNoVDB}`);
    }
    
    getListaSolicitacao(id: number,safra: number):Observable<any[]> {
        return this.services.get(`${this._mainRoute}/${id}/${safra}/listar`);
    }

    patchSalvarParecer(parecer: any) {
        return this.services.patch(`${this._mainRoute}`, parecer);
    }
}