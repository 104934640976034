import { finalize } from "rxjs/operators";
import { VcpService } from "src/app/services/vcp.service";

import { Component, Input, OnInit } from "@angular/core";

import {
  ContainerNota,
  MetaNota,
  Nota,
} from "../../../entities/NotaCertificacao";
import { UnidadeProdutiva } from "../../../entities/unidade-produtiva";

@Component({
  selector: "app-modal-etapa-vcp-nota",
  templateUrl: "./modal-etapa-vcp-nota.component.html",
  styleUrls: ["./modal-etapa-vcp-nota.component.scss"],
})
export class ModalEtapaVcpNotaComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() containerNota: ContainerNota;
  @Input() modal: any;

  // data: Nota[] = [];
  // meta: MetaNota;
  loadingNotas = true;

  constructor(public vcpService: VcpService) {}

  ngOnInit(): void {
    // this.vcpService
    //   .getNotas(this.unidadeProdutiva.id)
    //   .pipe(
    //     finalize(() => {
    //       this.loadingNotas = false;
    //     })
    //   )
    //   .subscribe(({ data, meta }) => {
    //     this.data = data;
    //     this.meta = meta;
    //   });
  }
}
