import { TextMaskModule } from "angular2-text-mask";
import { ModalModule } from "ngx-bootstrap/modal";

import { HttpClientModule } from "@angular/common/http";
import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Router } from "@angular/router";
import {
  FontAwesomeModule,
  FaIconLibrary,
} from "@fortawesome/angular-fontawesome";
import {
  faCloudDownloadAlt,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFilePdf,
  faInfoCircle,
  faSearch,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { NgbModule, NgbPagination } from "@ng-bootstrap/ng-bootstrap";
import { ShimmerModule } from "@sreyaj/ng-shimmer";
import { MomentModule } from "ngx-moment";
import { AutocompleteLibModule } from "angular-ng-autocomplete";

import { AppComponent } from "./app.component";
import { ROUTES } from "./app.router";
import { BaseComponent } from "./base/base.component";
import { EtapaAbrDashboardTdComponent } from "./components/etapa-abr-dashboard-td/etapa-abr-dashboard-td.component";
import { ExternalLinkBtnComponent } from "./components/external-link-btn/external-link-btn.component";
import { ExternalLinkComponent } from "./components/external-link/external-link.component";
import { ModalContainerComponent } from "./components/modal-container/modal-container.component";
import { SortableThComponent } from "./components/sortable-th/sortable-th.component";
import { StatusConviteComponent } from "./components/status-convite/status-convite.component";
import { UnidadeProdutivaStatusComponent } from "./components/unidade-produtiva-status/unidade-produtiva-status.component";
import { HomeComponent } from "./home/home.component";
import { AppInterceptorProvider } from "./interceptors/app-http.interceptor";
import { LoginComponent } from "./login/login.component";
import { ModalAceiteConviteComponent } from "./modal/modal-aceite-convite/modal-aceite-convite.component";
import { ModalAndamentoCertificao } from "./modal/modal-andamento-certificacao/modal-andamento-certificacao.component";
import { ModalCancelarCertificacaoComponent } from "./modal/modal-cancelar-certificacao/modal-cancelar-certificacao.component";
import { ModalCertificadoraComponent } from "./modal/modal-certificadora/modal-certificadora.component";
import { ModalConviteUsuario } from "./modal/modal-convidar-usuario/modal-convite-usuario.component";
import { ModalConviteComponent } from "./modal/modal-convite/modal-convite.component";
import { ModalDadosUp } from "./modal/modal-dados-up/modal-dados-up.component";
import { ModalDefinirSafra } from "./modal/modal-definir-safra/modal-definir-safra.component";
import { ModalEditarDados } from "./modal/modal-editar-dados/modal-editar-dados.component";
import { ModalEtapaPCNCComponent } from "./modal/modal-etapa-pcnc/modal-etapa-pcnc.component";
import { ModalEtapaVcp } from "./modal/modal-etapa-vcp/modal-etapa-vcp.component";
import { ModalEtapaVdpNotaComponent } from "./modal/modal-etapa-vdp/modal-etapa-vdp-nota/modal-etapa-vdp-nota.component";
import { ModalEtapaVdp } from "./modal/modal-etapa-vdp/modal-etapa-vdp.component";
import { ModalGerenciarEtapaComponent } from "./modal/modal-gerenciar-etapa/modal-gerenciar-etapa.component";
import { ModalGestaoAbrapaComponent } from "./modal/modal-gestao-abrapa/modal-gestao-abrapa.component";
import { ModalhistoricoAbr } from "./modal/modal-historico-abr/modal-historico-abr.component";
import { ModalLimiteBciComponent } from "./modal/modal-limite-bci/modal-limite-bci.component";
import { ModalComponent } from "./modal/modal.component";
import { YesNoEmptyPipe } from "./pipes/yes-no-empty.pipe";
import { AlertModalService } from "./services/alert-modal.service";
import { AppService } from "./services/app-service";
import { AuthService } from "./services/auth.service";
import { ErrorInterceptorProvider } from "./services/error.interceptor";
import { Services } from "./services/service";
import { SpinnerService } from "./services/spinner.service";
import { ToastService } from "./services/toast.service";
import { AlertModalComponent } from "./shared/alert-modal/alert-modal.component";
import { ConfirmModalComponent } from "./shared/confirm-modal/confirm-modal.component";
import { MultiStepsAbrapaComponent } from "./shared/multi-steps-abrapa/multi-steps-abrapa.component";
import { MultiStepsComponent } from "./shared/multi-steps/multi-steps.component";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { ToastComponent } from "./shared/toast/toast.component";
import { NotaNecessariaObtidaComponent } from "./components/nota-necessaria-obtida/nota-necessaria-obtida.component";
import { TitleNumberComponent } from "./components/title-number/title-number.component";
import { ModalEtapaCertificadoAbrComponent } from "./modal/modal-etapa-certificado-abr/modal-etapa-certificado-abr.component";
import { LabeledButtonComponent } from "./components/labeled-button/labeled-button.component";
import { ModalNotasComponent } from "./modal/modal-notas/modal-notas.component";
import { ModalEtapaVcpNotaComponent } from "./modal/modal-etapa-vcp/modal-etapa-vcp-nota/modal-etapa-vcp-nota.component";
import { ExibirVdpComponent } from "./modal/modal-gestao-abrapa/exibir-vdp/exibir-vdp.component";
import { ExibirVcpComponent } from "./modal/modal-gestao-abrapa/exibir-vcp/exibir-vcp.component";
import { StatusDotComponent } from "./components/status-dot/status-dot.component";
import { ModalLicencaBciComponent } from "./modal/modal-licenca-bci/modal-licenca-bci.component";
import { ModalEtapaLicencaBciComponent } from "./modal/modal-etapa-licenca-bci/modal-etapa-licenca-bci.component";
import { LabelValueComponent } from "./components/label-value/label-value.component";
import { ModalResponsavelAbrComponent } from "./modal/modal-responsavel-abr/modal-responsavel-abr.component";
import { ModalSimplesComponent } from "./components/modal-simples/modal-simples.component";
import { ModalUploadLicencaBciComponent } from "./modal/modal-upload-licenca-bci/modal-upload-licenca-bci.component";
import { AcessoConvitesComponent } from "./components/acesso-convites/acesso-convites.component";
import { ModalDataLimiteBciComponent } from "./modal/modal-data-limite-bci/modal-data-limite-bci.component";
import { TituloModalComponent } from "./components/titulo-modal/titulo-modal.component";
import { DropdownBtnComponent } from "./components/dropdown-btn/dropdown-btn.component";
import { DropdownBtnItemComponent } from "./components/dropdown-btn-item/dropdown-btn-item.component";
import { ModalEtapaGestaoAbrapaComponent } from "./modal/modal-gestao-abrapa/modal-etapa-gestao-abrapa/modal-etapa-gestao-abrapa.component";
import { ModalEtapaGestaoConviteComponent } from "./modal/modal-etapa-gestao-convite/modal-etapa-gestao-convite.component";
import { AtivadorTooltipComponent } from "./components/ativador-tooltip/ativador-tooltip.component";
import { InfoTooltipComponent } from "./components/info-tooltip/info-tooltip.component";
import { ModalEmitirNumeroCertificacaoComponent } from "./modal/modal-emitir-numero-certificacao/modal-emitir-numero-certificacao.component";
import { ModalEmitirNumeroLicencaBciComponent } from "./modal/modal-emitir-numero-licenca-bci/modal-emitir-numero-licenca-bci.component";
import * as Sentry from "@sentry/angular";
import { HeaderComponent } from "./layout/header/header.component";
import { PodeVerSetorPipe } from "./pipes/pode-ver-setor.pipe";
import { PodeVerSetorModalPipe } from "./pipes/pode-ver-setor-modal.pipe";
import { PodeVerSetorAndamentoPipe } from "./pipes/pode-ver-setor-andamento.pipe";
import { ModalVdpNotaExplicativaComponent } from "./modal/modal-etapa-vdp/modal-vdp-nota-explicativa/modal-vdp-nota-explicativa.component";
import { ModalVdpNotaExplicativaPreenchidaComponent } from "./modal/modal-etapa-vdp/modal-vdp-nota-explicativa-preenchida/modal-vdp-nota-explicativa-preenchida.component";
import { ReplaceNumberPipe } from "./pipes/replace-number.pipe";
import { BeneficiamentoAlgodoeiraPipe } from "./pipes/beneficiamento-algodoeira.pipe";
import { NgbTypeaheadConfig, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalSelecionarCertificadoraComponent } from "./modal/modal-certificadora/modal-selecionar-certificadora/modal-selecionar-certificadora.component";
import { ModalSolicitarRetornoComponent } from "./modal/modal-retorno/modal-solicitar-retorno/modal-solicitar-retorno.component";
import { ModalRetornoComponent } from "./modal/modal-retorno/modal-retorno.component";
import { GerenciarSolicitacaoRetornoComponent } from "./modal/modal-gerenciar-etapa/gerenciar-solicitacao-retorno/gerenciar-solicitacao-retorno.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { IconComponent } from "./shared/icon/icon.component";
import { BasicModalComponent } from "./shared/basic-modal/basic-modal.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ModalComponent,
    ModalDefinirSafra,
    ModalConviteUsuario,
    ModalEditarDados,
    ModalDadosUp,
    ModalEtapaVdp,
    ModalEtapaVcp,
    ModalhistoricoAbr,
    ModalEtapaPCNCComponent,
    MultiStepsComponent,
    SpinnerComponent,
    ModalConviteComponent,
    ModalAceiteConviteComponent,
    ModalAndamentoCertificao,
    ToastComponent,
    BaseComponent,
    LoginComponent,
    ConfirmModalComponent,
    AlertModalComponent,
    ModalGestaoAbrapaComponent,
    MultiStepsAbrapaComponent,
    IconComponent,
    BasicModalComponent,
    ModalGerenciarEtapaComponent,
    ModalCancelarCertificacaoComponent,
    ModalLimiteBciComponent,
    EtapaAbrDashboardTdComponent,
    YesNoEmptyPipe,
    SortableThComponent,
    UnidadeProdutivaStatusComponent,
    StatusConviteComponent,
    ModalEtapaVdpNotaComponent,
    ExternalLinkBtnComponent,
    ExternalLinkComponent,
    ModalContainerComponent,
    ModalCertificadoraComponent,
    NotaNecessariaObtidaComponent,
    TitleNumberComponent,
    ModalEtapaCertificadoAbrComponent,
    LabeledButtonComponent,
    ModalNotasComponent,
    ModalEtapaVcpNotaComponent,
    ExibirVdpComponent,
    ExibirVcpComponent,
    StatusDotComponent,
    ModalLicencaBciComponent,
    ModalEtapaLicencaBciComponent,
    LabelValueComponent,
    ModalResponsavelAbrComponent,
    ModalSimplesComponent,
    ModalUploadLicencaBciComponent,
    AcessoConvitesComponent,
    ModalDataLimiteBciComponent,
    TituloModalComponent,
    DropdownBtnComponent,
    DropdownBtnItemComponent,
    ModalEtapaGestaoAbrapaComponent,
    ModalEtapaGestaoConviteComponent,
    AtivadorTooltipComponent,
    InfoTooltipComponent,
    ModalEmitirNumeroCertificacaoComponent,
    ModalEmitirNumeroLicencaBciComponent,
    HeaderComponent,
    PodeVerSetorPipe,
    PodeVerSetorModalPipe,
    PodeVerSetorAndamentoPipe,
    ModalVdpNotaExplicativaComponent,
    ModalVdpNotaExplicativaPreenchidaComponent,
    ReplaceNumberPipe,
    BeneficiamentoAlgodoeiraPipe,
    ModalSelecionarCertificadoraComponent,
    ModalSolicitarRetornoComponent,
    ModalRetornoComponent,
    GerenciarSolicitacaoRetornoComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    RouterModule.forRoot(ROUTES),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    FontAwesomeModule,
    ModalModule.forRoot(),
    ShimmerModule,
    MomentModule,
    AutocompleteLibModule,
  ],
  exports: [SpinnerComponent, AlertModalComponent],
  providers: [
    Services,
    AuthService,
    AppService,
    ErrorInterceptorProvider,
    ToastService,
    AppInterceptorProvider,
    AlertModalService,
    SpinnerService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    NgbTypeaheadConfig,
    ModalSelecionarCertificadoraComponent,
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalComponent,
    ModalConviteUsuario,
    NgbPagination,
    ModalEditarDados,
    ConfirmModalComponent,
    AlertModalComponent,
  ],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faSearch);
    library.addIcons(faSyncAlt);
    library.addIcons(faInfoCircle);
    library.addIcons(faExternalLinkAlt);
    library.addIcons(faCloudDownloadAlt);
    library.addIcons(faFilePdf);
    library.addIcons(faExclamationTriangle);
  }
}
