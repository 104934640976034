import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { REDIRECIONAMENTO_EXTERNO } from '../app.api';
import { AuthService } from '../services/auth.service';
import { Services } from '../services/service';
import { SpinnerComponent } from '../shared/spinner/spinner.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) { }
  processoFinalizado = false;


  authorized = false;
  redirecionamentoExterno = '';

  ngOnInit(): void {
    const idUsuario = this.route.snapshot.queryParams.idUsuario;
    const token = this.route.snapshot.queryParams.token;


    this.authService.login(idUsuario, token)
      .subscribe(
        res => {
          this.authorized = this.authService.isAuthorized();
          this.redirecionamentoExterno = REDIRECIONAMENTO_EXTERNO;
          if (this.authorized) {
            this.router.navigate(['']);
          }
        },
        error => {
          this.authorized = this.authService.isAuthorized();
          this.redirecionamentoExterno = REDIRECIONAMENTO_EXTERNO;
          this.processoFinalizado = true;
        });
  }
}
