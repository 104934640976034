import { Status } from "./status";
export interface AndamentoCertificacao {
  status: Status;
  nomeCertificadora?: String;
  numeroCertificado: String;
}

export enum SetoresAndamentoCertificacao {
  CONVITES = "Status do convite",
  TERMO_ADESAO = "Acesso ao termo de adesao",
  VDP = "Setor VDP",
  VDP_BTN_ACESSO = "Botao de acesso ao setor VDP",
  PCNC = "Setor PCNC",
  PCNC_BTN_ACESSO = "Botao de acesso ao setor PCNC",
  VCP = "Setor VCP",
  VCP_BTN_ACESSO = "Botao de acesso ao setor VCP",
  ABR = "Setor ABR",
  ABR_BTN_ACESSO = "Botao de acesso ao setor ABR",
  DOWNLOAD_CERT_ABR = "Botao de download do certificado ABR",
  BCI = "Setor BCI",
  DOWNLOAD_LICENCA_BCI = "Botao de download de licença BCI"
}
