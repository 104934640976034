import { EtapaService } from './etapa.service';
import { Etapas } from "./../entities/etapas";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { AceiteBci, GestaoStatus } from "./../entities/gestao-status";
import { Observable, of } from "rxjs";

import { Injectable } from "@angular/core";

import { EtapaABR } from "../entities/etapas";
import { AppService } from "./app-service";
import { Services } from "./service";
import { delay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GestaoAbrapaService {
  constructor(public service: Services, private appService: AppService, private etapaService: EtapaService) {}

  getDataLimiteEditarConviteBci(): Observable<AceiteBci> {
    const safra = this.appService.getSafraSelecionada();
    return this.service.get(`UpCertificacao/${safra.id}/GetDataLimiteBci`);
  }

  getEtapasSafraSelecionada(
    unidadeProdutiva: UnidadeProdutiva
  ): Observable<Etapas[]> {
    return this.etapaService.getOpcoesGerenciarEtapas(unidadeProdutiva.id);
  }
}
