import { Services } from "./service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CertificadoraService {
  constructor(private service: Services) {}

  ListasCertificadora(estado: string) {
    return this.service.get(`Certificadora/ListarCertificadoras/${estado}`);
  }
}
