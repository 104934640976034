import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnidadeProdutiva } from "../../entities/unidade-produtiva";
import { CertificacaoService } from 'src/app/services/certificacao.service';
import { ToastType } from 'src/app/entities/toast';
import { ToastService } from 'src/app/services/toast.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'modal-cancelar-certificacao',
  templateUrl: './modal-cancelar-certificacao.component.html',
  //styleUrls: ['./modal-gestao-abrapa.component.css']
})
export class ModalCancelarCertificacaoComponent implements OnInit {

  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() podeVerBotaoCancelarCertAbr:boolean=true;
  @Output() atualizacaoRealizada = new EventEmitter();
  @Output() CarregarDadosModal = new EventEmitter();
  habilitarCancelarCertificacao = false;


  profileForm = new FormGroup({
    motivo: new FormControl('')
  })
  constructor(
    private modalService: NgbModal, 
    private toastService: ToastService,
    private spinnerService: SpinnerService,
    private certificacaoService: CertificacaoService) { }

  ngOnInit(): void {
    let usuarioLogado = JSON.parse(window.localStorage.getItem("usuario"));
    let perfis = usuarioLogado?.role;
    if(perfis !== null && perfis !== undefined){
      this.habilitarCancelarCertificacao = (perfis.indexOf("CERTIFICADORA") > -1);
    }
  }

  open(content) {
    this.modalService.open(content, { centered: true });

  }

  onSubmit() {
    this.spinnerService.show();
    const  motivo = this.profileForm.get('motivo').value.toString();
    this.certificacaoService.cancelar(this.unidadeProdutiva.id, motivo).subscribe(
      res => 
      {
        this.toastService.showToast(
          res ? ToastType.SUCCESS : ToastType.DANGER,
          res ? 'Sucesso' : 'Falha',
          res ? 'Certificação ABR cancelada com sucesso' : 'Falha ao cancelar Certificação ABR'
        );
        this.spinnerService.hide();
        this.CarregarDadosModal.emit();
        this.atualizacaoRealizada.emit();
      }
    );
  }
}