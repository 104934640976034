<ng-template #modal>
  <div
    class="modal-header"
    [ngClass]="{ 'pb-0': modalConfig?.noPaddingBottomModalTitle }"
    *ngIf="modalConfig?.modalTitle"
  >
    <h5 class="modal-title">{{ modalConfig?.modalTitle }}</h5>
    <button
      type="button"
      class="modal-close"
      (click)="close(this.modalConfig.closeModal)"
    >
      x
    </button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div
    class="modal-footer {{
      modalConfig.positionFooterButtons ? modalConfig.positionFooterButtons : ''
    }}"
  >
    <button
      type="button"
      class="btn btn-light"
      (click)="close(this.modalConfig.closeModal)"
      *ngIf="modalConfig.hideCloseButton === false"
      [disabled]="
        modalConfig.disableCloseButton !== undefined &&
        modalConfig.disableCloseButton()
      "
    >
      <app-icon
        *ngIf="this.modalConfig?.iconClose"
        [icon]="this.modalConfig?.iconClose"
        [styles]="this.modalConfig?.styleIconClose"
      ></app-icon>
      {{
        modalConfig?.closeButtonLabel
          ? modalConfig?.closeButtonLabel
          : "Cancelar"
      }}
    </button>
    <button
      type="button"
      class="btn btn-{{
        modalConfig.colorDenyButton ? modalConfig.colorDenyButton : 'danger'
      }}"
      (click)="deny(this.modalConfig.closeModal)"
      *ngIf="modalConfig.hideDenyButton === false"
      [disabled]="modalConfig.disableDenyButton"
    >
      <app-icon
        *ngIf="this.modalConfig?.iconDeny"
        [icon]="this.modalConfig?.iconDeny"
        [styles]="this.modalConfig?.styleIconDeny"
      ></app-icon>
      {{
        modalConfig?.denyButtonLabel ? modalConfig?.denyButtonLabel : "Revogar"
      }}
    </button>
    <button
      type="button"
      class="btn btn-{{
        modalConfig.colorSaveButton ? modalConfig.colorSaveButton : 'primary'
      }}"
      (click)="save(this.modalConfig.closeModal)"
      *ngIf="modalConfig.hideSaveButton === false"
      [disabled]="modalConfig.disableSaveButton"
    >
      <app-icon
        *ngIf="this.modalConfig?.iconSave"
        [icon]="this.modalConfig?.iconSave"
        [styles]="this.modalConfig?.styleIconSave"
      ></app-icon>
      {{
        modalConfig?.saveButtonLabel ? modalConfig?.saveButtonLabel : "Salvar"
      }}
    </button>
  </div>
</ng-template>
