import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "yesNoEmpty",
})
export class YesNoEmptyPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === "0") {
      return "NÃO";
    }

    if (value === "1") {
      return "SIM";
    }

    return "";
  }
}
