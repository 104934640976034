import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-title-number",
  templateUrl: "./title-number.component.html",
})
export class TitleNumberComponent implements OnInit {
  @Input() title: string;
  @Input() number: string;
  @Input() loading = false;

  constructor() {}

  ngOnInit(): void {}
}
