import {  Component, Input, OnDestroy, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ContainerNota } from 'src/app/entities/NotaCertificacao';
import { Safra } from 'src/app/entities/safra';
import { Status } from 'src/app/entities/status';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { EtapaService } from 'src/app/services/etapa.service';
import { NotaService } from 'src/app/services/nota.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UpCertificacaoService } from 'src/app/services/up-certificacao.service';
import { VcpService } from 'src/app/services/vcp.service';
import { VdpService } from 'src/app/services/vdp.service';

@Component({
  selector: 'modal-etapa-gestao-abrapa',
  templateUrl: './modal-etapa-gestao-abrapa.component.html',
  styleUrls: ['./modal-etapa-gestao-abrapa.component.scss']
})
export class ModalEtapaGestaoAbrapaComponent implements OnInit, OnDestroy  {

  @Input() unidadeProdutiva: UnidadeProdutiva;
  safra: Safra;
  status: Status;

  licencaBCI: string;
  certificacaoABR: string;

  containerNotaVcp: ContainerNota;
  containerNotaVdp: ContainerNota;

  loadingStatus = false;
  loadingNotaVcp = false;
  loadingNotaVdp = false;
  loadingLicencaBCI = false;
  loadingCertificacaoABR = false;

  constructor(
    private upCertificacaoService: UpCertificacaoService,
    private etapaService: EtapaService,
    private vcpService: VcpService,
    private vdpService: VdpService,
    private subscriptionService: SubscriptionService,
    private notaService: NotaService,
  ) {
    this.containerNotaVcp = this.notaService.iniciarContainerNota();
    this.containerNotaVdp = this.notaService.iniciarContainerNota();
  }
  ngOnInit(): void {
   this. _carregarDado();
  }
  _carregarDado(){
    this._carregarCertificacoes();
    this._carregarStatus();
    this._carregarNotas();
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  private _carregarStatus() {
    this.loadingStatus = true;
    const upCertificacao$ = this.upCertificacaoService
      .getStatusCertificacao(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingStatus = false)
      )
      .subscribe(status => {
        this.status = status;
      });
    this.subscriptionService.add(upCertificacao$);
  }

  private _carregarCertificacoes() {
    this._carregaCertificacaoABR();
    this._carregaCertificacaoBCI();
  }

  private _carregaCertificacaoABR() {
    this.loadingCertificacaoABR = true;
    const certificacaoABR$ = this.etapaService
      .getCertificadoABRSafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingCertificacaoABR = false)
      )
      .subscribe(res => this.certificacaoABR = res.numero);
    this.subscriptionService.add(certificacaoABR$);
  }

  private _carregaCertificacaoBCI() {
    this.loadingLicencaBCI = true;
    const certificacaoBCI$ = this.etapaService
      .getCertificadoBCISafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingLicencaBCI = false)
      )
      .subscribe(res => this.licencaBCI = res.numero);
    this.subscriptionService.add(certificacaoBCI$);
  }

  private _carregarNotas() {
    this._carregarStatusNotaVCP();
    this._carregarStatusNotaVDP();
  }

  private _carregarStatusNotaVCP() {
    this.loadingNotaVcp = true;
    const vcp$ = this.vcpService
      .getNotasSafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingNotaVcp = false)
      )
      .subscribe(res => {
        this.containerNotaVcp = res;
      })
    this.subscriptionService.add(vcp$);
  }

  private _carregarStatusNotaVDP() {
    this.loadingNotaVdp = true;
    const vdp$ = this.vdpService
      .getNotasSafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingNotaVdp = false)
      )
      .subscribe(res => {
        this.containerNotaVdp = res;
      });
    this.subscriptionService.add(vdp$);
  }

}
