import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastType } from "../entities/toast";
import { AlertModalService } from "../services/alert-modal.service";
import { AppService } from "../services/app-service";
import { AuthService } from "../services/auth.service";
import { ModalService } from "../services/modal.service";
import { Services } from "../services/service";
import { SpinnerService } from "../services/spinner.service";
import { ToastService } from "../services/toast.service";

@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.scss"],
})
export class BaseComponent implements OnInit {
  public spinnerVisibility: boolean = false;

  authorized = this.authService.isAuthorized();

  constructor(
    public authService: AuthService,
    public modalService: NgbModal,
    public appModalService: ModalService,
    public service: Services,
    private toast: ToastService,
    public appService: AppService,
    public alertService: AlertModalService,
    public bsModalService: BsModalService,
    public fb: FormBuilder,
    private spinnerService: SpinnerService
  ) {}


  ngOnInit(): void {
    this.spinnerService
      .isVisibleAsObservable()
      .subscribe((isVisible) => (this.spinnerVisibility = isVisible));
  }

  /**
   * @deprecated Use ToastService.showToast instead of. Avoid base component usage. Class has a lot of dependencies not used in all components.
   * @param type type of toast if it´s success, alert or danger.
   * @param header message header of the toast.
   * @param message message body of the toast
   */
  showToast(type: ToastType, header: string, message: string) {
    this.toast.showToast(type, header, message);
  }

  showSpinner() {
    this.spinnerService.show();
  }

  hideSpinner() {
    this.spinnerService.hide();
  }
}
