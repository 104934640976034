import {Component, Input, OnInit} from '@angular/core';
import {IconName, SizeProp, Styles} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() icon: IconName;
  @Input() size: SizeProp | undefined = undefined;
  @Input() spin: boolean = false;
  @Input() styles: Styles | undefined = undefined;

  ngOnInit(): void {}
}
