import { Component, OnInit, Input, Injectable } from '@angular/core';
import { UnidadeProdutiva } from "../../entities/unidade-produtiva";
import { NgbDatepickerI18n, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDefinirSafra } from '../modal-definir-safra/modal-definir-safra.component';
import { Safra } from '../../entities/safra';
import { Services } from '../../services/service';
import { UsuarioConvidado } from '../../entities/usuario-convidado';
import { HistoricoCertificacao } from '../../entities/historicoCertificacao';
import { Etapas } from '../../entities/etapas';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const I18N_VALUES = {
  'pt': {
    weekdays: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
    months: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'pt';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'modal-limite-bci',
  templateUrl: './modal-limite-bci.component.html',
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}],
  styleUrls: ['./modal-limite-bci.component.scss']
})
export class ModalLimiteBciComponent implements OnInit {

dataLimite: NgbDateStruct;
date: {year: number, month: number, day: number};

  constructor(private modalService: NgbModal, private services: Services) { }

  ngOnInit(): void { }

  open(content) {
    this.modalService.open(content, { centered: true })

  }
}