import { Component, OnInit, Input, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { UnidadeProdutiva } from "../../entities/unidade-produtiva";
import { HistoricoCertificacao } from '../../entities/historicoCertificacao';
import { Step } from 'src/app/shared/multi-steps/multi-steps.component';
import { ModalEtapaGestaoAbrapaComponent } from './modal-etapa-gestao-abrapa/modal-etapa-gestao-abrapa.component';

@Component({
  selector: 'modal-gestao-abrapa',
  templateUrl: './modal-gestao-abrapa.component.html',
  styleUrls: ['./modal-gestao-abrapa.component.scss']
})
export class ModalGestaoAbrapaComponent implements OnInit,AfterViewInit {

  @Input() historicoCertificacao: HistoricoCertificacao;
  @Input() esconderAndamentoCertificacao: boolean = false;
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @ViewChild(ModalEtapaGestaoAbrapaComponent) modalGestao:ModalEtapaGestaoAbrapaComponent;

  steps: Step[];
  defaultActiveStepLabel: string;
  activeStepLabel: string;

  loading = true;

  modalAberto = false;

  showAndamentoCertificacao = false;

  constructor() { }

  ngOnInit(): void {
    this.loading = true;
    this._defineSteps();
  }

  ngAfterViewInit() {
    this.modalGestao?._carregarDado();
  }

  private _defineSteps(){
    this.steps = [
      {
        label:'Gestão ABRAPA',
        active: true
      }
    ];

    this.loading = false;
  }

  onChangeStep(step: Step) {
    this.activeStepLabel = step.label;
  }

  onShowAndamentoCertificacao() {
    this.showAndamentoCertificacao = !this.showAndamentoCertificacao;
  }
  onUpdate(){
    this.modalGestao._carregarDado();
  }
}
