import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastType } from 'src/app/entities/toast';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { FinalizandoCertificacaoService } from 'src/app/services/finalizando-certificacao.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'modal-emitir-numero-licenca-bci',
  templateUrl: './modal-emitir-numero-licenca-bci.component.html',
  styleUrls: ['./modal-emitir-numero-licenca-bci.component.scss']
})
export class ModalEmitirNumeroLicencaBciComponent implements OnInit {

  @Input() unidadeProdutiva: UnidadeProdutiva;

  @Output() emitido = new EventEmitter();

  constructor(
    private finalizaCertificacaoService: FinalizandoCertificacaoService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(numeroLicenca: HTMLInputElement, modal: any) {
    if(!numeroLicenca.value.length) {
      return;
    }

    this.finalizaCertificacaoService.respostaFormularioBCI(this.unidadeProdutiva, numeroLicenca.value)
      .subscribe(res => {
        this.toastService.showToast(ToastType.SUCCESS, 'Licença BCI', 'Licença BCI definida!');
        modal.close();
        this.emitido.emit();
      });
  }

}
