import { AppService } from './app-service';
import { Services } from './service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CertificacaoService {

  constructor(private service: Services, private app: AppService) { }

  cancelar(unidadeProdutivaId: number, motivo: string) {
    const { id } = this.app.getSafraSelecionada();
    const usuario = this.app.getUsuario();
    const objPost= {"UnidadeProdutivaId": unidadeProdutivaId,"SafraId":id,"UsuarioCpf":usuario.unique_name,"Motivo":motivo};
    return this.service.post(`CancelarCertificacao/cancelar`, objPost);
  }
}
