<div class="d-flex justify-content-lg-end align-items-start">
  <div class="btn-group">
    <button type="button" class="btn btn-outline-secondary dropdown-toggle btn-sm" data-toggle="dropdown"
      aria-haspopup="true" aria-expanded="false">
      {{ labelAtivadora }}
    </button>
    <div class="dropdown-menu dropdown-menu-right">
      <ng-content></ng-content>
    </div>
  </div>
</div>
