import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceNumber'
})
export class ReplaceNumberPipe implements PipeTransform {

  transform(value: string): unknown {
    if (!!value) {
      return value.replace(/,/g, '.');
    }
    return 0;
  }

}
