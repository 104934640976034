import { Component, OnInit } from "@angular/core";
import { Safra } from "./entities/safra";
import { BaseComponent } from "./base/base.component";
import { AuthService } from "./services/auth.service";
import { REDIRECIONAMENTO_EXTERNO } from "./app.api";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends BaseComponent {}
