import { APP_API } from './../app.api';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthentication(request);
    return next.handle(request);
  }

  // here you add the bearer in every request
  addAuthentication(req: HttpRequest<any>): HttpRequest<any> {
    const authToken = this.authService.getToken(); // get the token from a service

    if (authToken.hasToken() && req.url.includes(APP_API)) {
      req = req.clone({
        headers: req.headers.set('Authorization', authToken.authorization())
      });
    }
    return req;
  }
}

export const AppInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AppHttpInterceptor,
  multi: true
};
