<titulo-modal>{{title}}</titulo-modal>
<p class="mb-0">Nota necessária</p>
<h5>
  <span class="dot-black"></span>
  <strong>&nbsp;{{ necessaria }}%</strong>
</h5>
<p class="mb-0">Nota obtida</p>
<h5>
  <span [ngClass]="obtida < necessaria ? 'dot-red' : 'dot-green'"></span>
  <strong>&nbsp;{{ obtida }}%</strong>
</h5>
