import { AppService } from './services/app-service';
import { ConfirmarNotaExplicativa, NotaExplicativa } from './entities/nota-explicativa';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { Services } from './services/service';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotaExplicativaService {

  constructor(private services: Services, private appService: AppService) { }



  getNotaExplicativa(unidadadeProdutiva: UnidadeProdutiva): Observable<NotaExplicativa[]> {
    return this.services.get(`NotaExplicativa/listaUps/${unidadadeProdutiva.idProdutorGrupo}`);
  }

  verificaNotaExplicativa(unidadadeProdutiva: UnidadeProdutiva): Observable<boolean> {
    const safra = this.appService.getSafraSelecionada();
    return this.services.get(`NotaExplicativa/VerificarExistenciaNotaEx/${unidadadeProdutiva.id}/${safra.id}`);
  }

  gerarNotaExplicativa(unidadadeProdutiva: UnidadeProdutiva) {
    const safra = this.appService.getSafraSelecionada();
    return this.services.get(`NotaExplicativa/gerarNotaExplicativa/${unidadadeProdutiva.id}/${safra.id}`);
  }

  confirmarNotaExplicativa(notaExplicativa: ConfirmarNotaExplicativa) {
    return this.services.post(`NotaExplicativa/confirmarNotaExplicativa`, notaExplicativa);
  }
  previewNotaExplicativa(notaExplicativa: ConfirmarNotaExplicativa) {
    const safra = this.appService.getSafraSelecionada();
    notaExplicativa.safraId = safra.id;
    return this.services.post(`NotaExplicativa/gerarNotaExplicativaParcial`, notaExplicativa);
  }
}
