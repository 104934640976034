export class Formulario {
    id : number
    hash : string
    identificadorPessoa : string
    nomePessoa : string
    idProdutorGrupo : number
    url : string
}

export enum TipoFormulario {
  VCP = 1,
  VDP = 2,
  PCNC = 3
}
