import { UnidadeProdutiva } from "./../../entities/unidade-produtiva";
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Step } from "src/app/shared/multi-steps/multi-steps.component";
import { ModalEtapaGestaoConviteComponent } from "../modal-etapa-gestao-convite/modal-etapa-gestao-convite.component";

@Component({
  selector: "app-modal-responsavel-abr",
  templateUrl: "./modal-responsavel-abr.component.html",
  styleUrls: ["./modal-responsavel-abr.component.scss"],
})
export class ModalResponsavelAbrComponent implements OnInit,AfterViewInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Output() etapaTrocada = new EventEmitter();
  @ViewChild(ModalEtapaGestaoConviteComponent) modalGestao:ModalEtapaGestaoConviteComponent;

  modalAberto = false;

  steps: Step[] = [];

  loading = false;

  constructor(

  ) { }

  ngOnInit(): void {
    this.steps = [
      {
        label: "Convite",
        active: true,
      },
    ];
  }

  ngAfterViewInit() {
    this.modalGestao?._inicializar();
  }
  atualizarEtapa() {
    this.etapaTrocada.emit();
  }
  onUpdate(){
    this.modalGestao._inicializar();
  }
}
