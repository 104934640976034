import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Safra } from "../entities/safra";
import { Status } from "../entities/status";
import { UnidadeProdutiva } from "../entities/unidade-produtiva";
import { AppService } from "./app-service";
import { Services } from "./service";

@Injectable({
  providedIn: "root",
})
export class UpCertificacaoService {
  constructor(private appService: AppService, private services: Services) {}

  aceitouParticiparBCI(unidadeProdutivaId: number): Observable<any> {
    const safra = this.appService.getSafraSelecionada();

    return this.services.get(
      `UpCertificacao/${unidadeProdutivaId}/${safra.id}/GetAceiteBci`
    );
  }

  editarParticipacaoConviteBci(
    aceitou: boolean,
    unidadeProdutivaId: number
  ): Observable<void> {
    const safra = this.appService.getSafraSelecionada();

    return this.services.post(
      `UpCertificacao/${unidadeProdutivaId}/${safra.id}/aceiteBci`,
      {
        aceite: aceitou,
      }
    );
  }

  definirDataLimiteBci(dataLimite: string): Observable<void> {
    const safra = this.appService.getSafraSelecionada();
    return this.services.postWithParams(
      `UpCertificacao/${safra.id}/PostDefinirDataLimite`,
      {
        safraId: safra.id,
        dataLimite: dataLimite,
      }
    );
  }

  getStatusCertificacao(
    unidadeProdutiva: UnidadeProdutiva
  ): Observable<Status> {
    const safra = this.appService.getSafraSelecionada();
    return this.appService.formatarStatus(
      this.services.get(
        `UpCertificacao/${unidadeProdutiva.id}/${safra.id}/status`
      )
    );
  }
  uploadLicencaBci(unidadeProdutiva: UnidadeProdutiva, arquivoEnviado: any) {
    const safra = this.appService.getSafraSelecionada();

    return this.services.post(
      `UpCertificacao/${unidadeProdutiva.id}/${safra.id}/uploadCertificadoBci`,
      {
        conteudo: arquivoEnviado,
        nomeArquivo: "licenca.pdf",
      }
    );
  }

  downloadLicencaBci(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    const safra = this.appService.getSafraSelecionada();
    return this.services.get(
      `UpCertificacao/${unidadeProdutiva.id}/${safra.id}/downloadCertificadoBci`
    );
  }

  verificaDownloadLicencaBci(
    unidadeProdutiva: UnidadeProdutiva
  ): Observable<any> {
    const safra = this.appService.getSafraSelecionada();
    return this.services.get(
      `UpCertificacao/${unidadeProdutiva.id}/${safra.id}/verificaExistenciaCertificadoBci`
    );
  }

  AlterarCertificadoraUp(
    unidadeProdutiva: UnidadeProdutiva,
    certificadoraId: string
  ) : Observable<any>{
    const safra = this.appService.getSafraSelecionada();
    return this.services.post(`UpCertificacao/AlterarCertificadoraUp`, {
      UpId: unidadeProdutiva.id,
      SafraId: safra.id,
      CertificadoraId: Number(certificadoraId),
    });
  }
}
