import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  defaultActiveStepLabel$ = new BehaviorSubject<string>("");
  safraCertificacao$ = new BehaviorSubject<number>(0);

  constructor() { }

  changeDefaultActiveStepLabel(newValue: string) {
    this.defaultActiveStepLabel$.next(newValue);
  }

  getDefaultActiveStepLabel() : Observable<string> {
    return this.defaultActiveStepLabel$.asObservable();
  }

  changeSafraCertificacao(newValue: number) {
    this.safraCertificacao$.next(newValue);
  }

  getSafraCertificacao() : Observable<number> {
    return this.safraCertificacao$.asObservable();
  }
}
