import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ContainerNota } from "src/app/entities/NotaCertificacao";
import { ToastService } from "src/app/services/toast.service";
import { ToastType } from "src/app/entities/toast";

@Component({
  selector: "app-exibir-vdp",
  templateUrl: "./exibir-vdp.component.html",
})
export class ExibirVdpComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() loading: boolean;
  @Input() containerNota: ContainerNota;

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {}

  get statusAprovacao() {
    if (!this.containerNota.temNota) {
      return false;
    }
    return this.containerNota.meta.aprovado;
  }

  open(content) {
    if (!this.containerNota.temNota) {
      return this.toastService.showToast(
        ToastType.INFO,
        "Nota VDP",
        "Etapa VDP não iniciada."
      );
    }

    this.modalService.open(content, { centered: true, size: "lg" });
  }
}
