import { Component, Input } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import {
    debounceTime,
    map,
    switchMap,
} from "rxjs/operators";
import { UsuarioConvidado } from "src/app/entities/usuario-convidado";
import { Validators } from '@angular/forms';
import { Helpers } from 'src/app/utils/helpers';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { BaseComponent } from 'src/app/base/base.component';
import { Status } from 'src/app/entities/status';
import { ToastType } from "src/app/entities/toast";

@Component({
    selector: "modal-convite-usuario",
    templateUrl: "./modal-convite-usuario.component.html",
})
export class ModalConviteUsuario extends BaseComponent {

    @Input() public cpfMask = { mask: Helpers.cpfMask, keepCharPositions: true};
    @Input() public telephoneMask = { mask: Helpers.telephoneMask, keepCharPositions: true};
    @Input() public mobileMask = { mask: Helpers.mobileMask, keepCharPositions: true};
    usuario: any;
    produtor: any;
    convidar: boolean = false;
    @Input() unidadeProdutiva: UnidadeProdutiva;
    public status: Status;
    formConvite = this.fb.group({
        nome: ['', Validators.required],
        cpf: ['', Validators.required],
        email: ['', Validators.compose([Validators.required, Validators.email])],
        telefoneFixo: ['', Validators.required],
        celular: ['', Validators.required],
        unidadeProdutivaCertificacaoId: [''],
        isSinda: [false]
    });

    ngOnInit() {
        this.formConvite.controls.unidadeProdutivaCertificacaoId.setValue(this.unidadeProdutiva.id)
    }

    open(content) {
        this.modalService.open(content, { centered: true });
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            map(term =>
                this.buscarUsuariosSinda(term))
            )


    formatter(x: {nomeCompleto : string, userName : string, email : string}) {
        return x.nomeCompleto +" - "+ x.userName +" - "+ x.email;
    }

    buscarUsuariosSinda(filtro : string) : any {
        this.service.getBy("Convite/getFilterUsersSinda", filtro).subscribe(x => {
            this.usuario = x
        })
        return this.usuario
    }

    onSubmit() {
        let usuarioConvidado = new UsuarioConvidado();
        Object.assign(usuarioConvidado, this.formConvite.getRawValue())
        var result$ = this.alertService.showConfirm();
        result$.asObservable().pipe(
            switchMap(x => {
                if(x){
                    this.showSpinner();
                    return this.service.post('Convite/convidar', usuarioConvidado)
                }
                else{
                    return EMPTY
                }
            })
        ).subscribe(x => {
            this.convidar = false;
            this.formConvite.reset()
            this.formConvite.enable()
            this.hideSpinner();
            this.showToast(ToastType.SUCCESS, 'Sucesso', x.message);
            this.ngOnInit();
        })
    }

    novoConvite() {
        this.convidar = true;
    }

    convidarSinda(usuarioSinda) {
        this.convidar = true;
        this.formConvite.controls.nome.setValue(usuarioSinda.item.nomeCompleto);
        this.formConvite.controls.cpf.setValue(usuarioSinda.item.userName);
        this.formConvite.controls.email.setValue(usuarioSinda.item.email);
        this.formConvite.controls.telefoneFixo.setValue(usuarioSinda.item.telefonePrincipal);
        this.formConvite.controls.celular.setValue(usuarioSinda.item.telefoneSecundario);
        this.service.getBy("Convite/getUserIsSinda", usuarioSinda.item.userName).subscribe(x => {
            this.formConvite.controls.isSinda.setValue(x);
        });

        if(usuarioSinda.item.nomeCompleto != "Não informado")
            this.formConvite.controls.nome.disable();
        if(usuarioSinda.item.userName.match(Helpers.cpfValidation))
            this.formConvite.controls.cpf.disable();

        this.formConvite.controls.email.disable();
    }

    limparConvite(){
        this.produtor = null;
        this.convidar = false;
        this.formConvite.reset();
        this.formConvite.enable();
    }
}
