import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-labeled-button",
  templateUrl: "./labeled-button.component.html",
  styleUrls: ["./labeled-button.component.scss"],
})
export class LabeledButtonComponent implements OnInit {
  @Input() label: string;

  constructor() {}

  ngOnInit(): void {}
}
