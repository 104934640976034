import { AppService } from "./app-service";
import { Services } from "./service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CertificadoAbrService {
  constructor(private service: Services, private appService: AppService) { }

  downloadCertificado(unidadeProdutivaId: number) {
    const safra = this.appService.getSafraSelecionada();
    return this.service.get(`FinalizandoCertificacao/${unidadeProdutivaId}/${safra.id}/gerarCertificado`);
  }

  verificarCertificado(unidadeProdutivaId: number) {
    const safra = this.appService.getSafraSelecionada();
    return this.service.get(`FinalizandoCertificacao/${unidadeProdutivaId}/${safra.id}/verificarCertificado`);
  }
}
