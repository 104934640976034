import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertModalComponent } from '../shared/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from '../shared/confirm-modal/confirm-modal.component';

export enum AlertTypes {
    DANGER = 'danger',
    SUCCESS = 'success'
}

@Injectable({
    providedIn: 'root'
})
export class AlertModalService {
    constructor(private bsModalService: BsModalService) {}

    private showAlert(message: string, type: string) {
        const bsModalRef: BsModalRef = this.bsModalService.show(AlertModalComponent);
        bsModalRef.content.type = type;
        bsModalRef.content.message = message;
    }

    showAlertDanger(message) {
        this.showAlert(message, AlertTypes.DANGER);
    }

    showAlertSuccess(message) {
        this.showAlert(message, AlertTypes.SUCCESS);
    }

    showConfirm(title?: string, msg?: string, okTxt?: string, cancelTxt?: string) {
        const bsModalRef: BsModalRef = this.bsModalService.show(ConfirmModalComponent, { backdrop: true });

        if (title) {
            bsModalRef.content.title = title;
        }
        if (msg) {
            bsModalRef.content.msg = msg;
        }
        if (okTxt) {
            bsModalRef.content.okTxt = okTxt;
        }
        if (cancelTxt) {
            bsModalRef.content.cancelTxt = cancelTxt;
        }

        return (bsModalRef.content as ConfirmModalComponent).confirmResult;
    }
}
