import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dropdown-btn-item',
  templateUrl: './dropdown-btn-item.component.html',
  styleUrls: ['./dropdown-btn-item.component.scss']
})
export class DropdownBtnItemComponent implements OnInit {

  @Input() marginBottom: boolean = false;

  @Output() actionPerformed = new EventEmitter();

  @Input() disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

}
