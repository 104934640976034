import { Component, HostListener, OnInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  width: any;

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
    this.width = window.innerWidth;
  }

  constructor(public service: HeaderService) {}

  ngOnInit(): void {
    this.width = window.innerWidth;
  }
}
