<modal-simples
  [isBtn]="true"
  labelAtivador="Preencher dados da licença."
  titulo="Definir número da certificação"
  (confirmar)="onSubmit(numeroLicenca, $event)"
>
  <div class="d-flex flex-column">
    <label>Licença BCI</label>
    <div style="max-width: 250px; padding: 10px;">
      <input #numeroLicenca class="form-control" type="text" maxlength="25" />
    </div>
  </div>
</modal-simples>
