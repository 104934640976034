<titulo-modal>{{ title }}</titulo-modal>

<ng-container *ngIf="!loading; else shimmer">
  <div class="content-responsive">
    <p class="mb-0">Número</p>
    <p class="h6">
      <strong>{{ number }}</strong>
    </p>
  </div>
</ng-container>

<ng-template #shimmer>
  <div class="d-flex flex-column">
    <shimmer width="90px" height="15px"></shimmer>
    <shimmer width="120px" height="18px"></shimmer>
  </div>
</ng-template>
