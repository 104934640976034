import { Injectable } from '@angular/core';
import { Safra } from '../entities/safra';
import { AppService } from './app-service';
import { Services } from './service';
import { SpinnerService } from './spinner.service';
import { AuthService } from './auth.service';
import { finalize } from 'rxjs/operators';
import { REDIRECIONAMENTO_EXTERNO } from "../app.api";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  safraEscolhida: Safra;
  listaSafras: Safra[];
  nomeUsuario: String;
  loginUsuario: String;
  inicializado = false;

  constructor(
    private appService: AppService,
    private service: Services,
    private spinnerService: SpinnerService,
    private authService: AuthService
  ) { }

  inicializar() {
    this.inicializado = false;
    this.carregarSafras();
    this.carregarDadosUsuario();
  }

  carregarSafras() {
    this.service.getAll("Safra")
      .pipe(
        finalize(() => {
          this.inicializado = true;
        })
      )
      .subscribe(
        (safras) => {
          this.listaSafras = safras as Safra[];
          this.safraEscolhida = this?.listaSafras[0];

          window.localStorage.setItem(
            "safraEscolhida",
            JSON.stringify(this.safraEscolhida)
          );

          this.appService.emitDate(this.safraEscolhida.id);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  carregarDadosUsuario() {
    const dadosUsuario = JSON.parse(window.localStorage.getItem("usuario"));
    this.nomeUsuario = this.nomeSobrenome(dadosUsuario?.NomeUsuario);
    this.loginUsuario = dadosUsuario?.unique_name;
  }

  nomeSobrenome(str: string) {
    str = str.trim();
    
    if (!str) {
      return '';
    }

    const arr = str.split(" ");
    if (arr.length === 1) {
      return arr[0];
    }

    if (arr[1][0].toUpperCase() != arr[1][0]) {
      arr.splice(1, 1);
    }
    const res = arr.slice(0, 2).join(' ');
    return res;
  }

  atualizarGrid(id) {
    this.appService.emitDate(id);
    this.listaSafras.map((element) => {
      if (element.id == id) this.safraEscolhida = element;
      window.localStorage.setItem(
        "safraEscolhida",
        JSON.stringify(this.safraEscolhida)
      );
    });
  }

  logout() {
    this.authService.logout();
    window.location.href = REDIRECIONAMENTO_EXTERNO;
  }
}
