import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../entities/api-response';
import { ContainerNota, MetaNota, Nota, StatusNota } from '../entities/NotaCertificacao';

@Injectable({
  providedIn: 'root'
})
export class NotaService {

  constructor() { }

  iniciarContainerNota(): ContainerNota {
    return {
      data: [],
      meta: {
        'aprovado': false,
        'notaMinimaPerguntas': 100
      },
      temNota: false,
      statusNota: StatusNota.NAO_INICIADO
    }
  }

  criarContainerNota(response: ApiResponse): ContainerNota {
    const temNota = response.data && response.meta;
    return {
      data: response.data,
      meta: response.meta,
      temNota,
      statusNota: this.criarStatusNota(temNota, response.meta?.aprovado)
    }
  }

  criarStatusNota(temNota: boolean, aprovado: boolean) {
    if (!temNota) {
      return StatusNota.NAO_INICIADO;
    }
    return aprovado ? StatusNota.APROVADO : StatusNota.REPROVADO;
  }

  formatarNotas(
    res: Observable<ApiResponse<Nota, MetaNota>>
  ): Observable<ContainerNota> {
    return res.pipe(
      map(res => this.criarContainerNota(res))
    );
  }
}
