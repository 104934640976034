<modal-simples
  [isBtn]="true"
  labelAtivador="Preencher dados do Certificado"
  titulo="Definir número da certificação"
  [disabled]="!preencherCertificado"
  (confirmar)="onSubmit(numeroCertificado, $event)"
>
  <div class="d-flex flex-column">
    <label>Certificado ABR</label>
    <div style="max-width: 250px">
      <input
        #numeroCertificado
        class="form-control"
        type="text"
        maxlength="10"
        [(ngModel)]="certificado"
      />
      <div style="display: flex; justify-content: end">
        {{ certificado.length || 0 }}/10
      </div>
    </div>
  </div>
</modal-simples>
