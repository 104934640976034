import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map } from "rxjs/operators";
import { DadosParecerSolicitacaoRetorno } from "src/app/entities/dados-parecer-solicitacao-retorno";
import { ToastType } from "src/app/entities/toast";
import { AlertModalService } from "src/app/services/alert-modal.service";
import { SolicitarRetornoEtapaService } from "src/app/services/solicitar-retono-etapa.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { ToastService } from "src/app/services/toast.service";
import { StatusSolicitacaoDotColorEnum } from "../../modal-retorno/status-solicitacao-dot-color-enum";

@Component({
    selector: 'app-gerenciar-solicitacao-retorno',
    templateUrl: './gerenciar-solicitacao-retorno.component.html',
})

export class GerenciarSolicitacaoRetornoComponent implements OnInit {
    dadosParecerSolicitacaoRetorno: DadosParecerSolicitacaoRetorno;
    @Input() dadosSolicitacao: any;
    @Output() update = new EventEmitter();
    habilitarBtn: boolean = true;
    modalRef: NgbModalRef;

    constructor(
        private modalService: NgbModal,
        private toastService: ToastService,
        private solicitacaoServices: SolicitarRetornoEtapaService,
        private spinner: SpinnerService,
        private alert: AlertModalService
    ) { }

    ngOnInit(): void { }

    openModal(content) {
        this.modalRef = this.modalService.open(content, { size: 'md', centered: true });
        this.buscarDadosSolicitacao();
    }

    buscarDadosSolicitacao() {
        this.spinner.show();

        this.solicitacaoServices
            .getById(this.dadosSolicitacao.id)
            .subscribe(
                (data) => {
                    this.dadosParecerSolicitacaoRetorno = data;
                    if (data.status !== "Em_analise") {
                        this.habilitarBtn = false;
                    }
                    this.spinner.hide();
                },
                (err) => {
                    this.spinner.hide();
                    this.toastService.showToast(
                        ToastType.DANGER,
                        "Error",
                        "houve algum erro."
                    );
                }
            );
    }

    submit(parecer: boolean) {
        let dadosParecerSolicitacao = {
            IdSolicitacao: this.dadosSolicitacao.id,
            Parecer: parecer
        };

        this.alert.showConfirm(
            ``,
            `Você tem certeza que deseja ${(parecer ? 'Aprovar' : 'Reprovar')} esta solicitação de retorno de etapa para esta Unidade Produtiva? Esta ação é irreversível.`
        ).pipe(
            map((confirm) => {
                if(confirm){
                    this.spinner.show();
                    this.solicitacaoServices
                    .patchSalvarParecer(dadosParecerSolicitacao)
                    .subscribe(
                        (res) => {
                            this.spinner.hide();
                            this.buscarDadosSolicitacao();
                            this.update.emit();
                            this.modalRef.close();
                        },
                        (err) => {
                            this.toastService.showToast(
                                ToastType.DANGER,
                                "Error",
                                "houve algum erro."
                            );
                            this.spinner.hide();
                            this.modalRef.close();
                        }
                    );
                }
            })
        ).subscribe()
    }

    buscaCorEtapa(status: string) {
        return StatusSolicitacaoDotColorEnum[status];
    }
}