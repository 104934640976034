import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

    @Input() title: string = 'Atenção';
    @Input() msg: string = 'Deseja realmente confirmar essa ação?';
    @Input() cancelTxt: string = 'Cancelar';
    @Input() okTxt: string = 'Sim';

    confirmResult: Subject<boolean>;

    constructor(private bsModalRef: BsModalRef) { }

    ngOnInit(): void {
        this.confirmResult = new Subject();
    }

    onConfirm() {
        this.confirmAndClose(true)
    }

    onClose() {
        this.confirmAndClose(false)
    }

    private confirmAndClose(value: boolean) {
        this.confirmResult.next(value);
        this.bsModalRef.hide();
    }

}
