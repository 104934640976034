import { Component, Input, OnInit } from "@angular/core";

import { EtapaABR } from "../../entities/etapas";
import { Status } from "../../entities/status";

@Component({
  selector: "app-unidade-produtiva-status",
  templateUrl: "./unidade-produtiva-status.component.html",
  styleUrls: ["./unidade-produtiva-status.component.scss"],
})
export class UnidadeProdutivaStatusComponent implements OnInit {
  @Input() status: Status;

  emEtapaSemAtor = false;

  constructor() {}

  ngOnInit(): void {
    if (
      this.status &&
      (this.status.etapa.toUpperCase()  === EtapaABR.NAO_INICIADO.toUpperCase()  ||
        this.status.etapa.toUpperCase()  === EtapaABR.NAO_OPTANTE_BCI.toUpperCase() ||
      this.status.etapa.toUpperCase()  === EtapaABR.NAO_CONVIDADA.toUpperCase() )
    ) {
      this.emEtapaSemAtor = true;
    }
  }
}
