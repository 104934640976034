import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-external-link-btn",
  templateUrl: "./external-link-btn.component.html",
  styleUrls: ["./external-link-btn.component.scss"],
})
export class ExternalLinkBtnComponent implements OnInit {
  icon = faExternalLinkAlt;

  @Input() disabled = false;
  @Input() isBtn = true;

  @Output() action = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  actionPerform() {
    this.action.emit();
  }
}
