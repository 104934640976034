<!--<button [disabled]="disabled" *ngIf="isBtn ; else elseLink"
  (click)="open(content)"
  class="btn btn-sm btn-primary">
  {{ labelAtivador }}
</button>

<ng-template #elseLink>
  <a
    href="#"
    (click)="open(content)"
    class="mb-2 mr-2">{{ labelAtivador }}
  </a>
</ng-template>-->

<ativador-tooltip [isBtn]="isBtn" [label]="labelAtivador" [disabled]="disabled" [tooltipEnabled]="tooltip.length"
  [tooltipText]="tooltip" (action)="open(content)">
</ativador-tooltip>

<ng-template #content let-modal>
  <h3 *ngIf="titulo" class="titulo">{{ titulo }}</h3>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div *ngIf="temRodape" class="modal-footer">
    <button type="button" class="btn btn-light btn-md" (click)="modal.close()">{{ labelBtnCancelar }}</button>
    <button type="button" [disabled]="btnDisabled" class="btn btn-primary btn-md" (click)="onConfirmar(modal)">{{
      labelBtnSalvar }}</button>
  </div>
</ng-template>