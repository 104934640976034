import { Component, Input, OnInit } from '@angular/core';
import { StatusNota } from 'src/app/entities/NotaCertificacao';
@Component({
  selector: 'app-status-dot',
  templateUrl: './status-dot.component.html',
  styleUrls: ['./status-dot.component.scss']
})

export class StatusDotComponent {

  @Input() status: StatusNota ;

  get dotRed() {
    return status !== StatusNota.APROVADO;
  }

  get dotGreen() {
    return status === StatusNota.APROVADO;
  }

}
