<a href="#" (click)="open(content)"> Dados Completos da UP </a>
<ng-template #content let-modal>
  <br />
  <div
    class="col-lg-12 my-2 content-responsive"
    *ngIf="detalheUnidadeProdutiva"
  >
    <h5 class="modal-title"><strong>Dados da Unidade Produtiva</strong></h5>
    <br />
    <div class="modal-body">
      <titulo-modal>Dados de Cadastro</titulo-modal><br />
      <div class="col-lg-12">
        <p class="text-muted">Dados gerais</p>
        <div class="row">
          <div class="col-lg-3">
            <label for="" class="text-muted">Nome</label><br />
            <label for="">{{
              detalheUnidadeProdutiva.nomeUnidadeProdutiva
            }}</label>
          </div>
          <div class="col-lg-2">
            <label for="" class="text-muted">Associação</label><br />
            <label for="">{{ detalheUnidadeProdutiva.nomeAssociacao }}</label>
          </div>
          <div class="col-lg-2">
            <label for="" class="text-muted">Status</label><br />
            <label for="">{{ detalheUnidadeProdutiva.status }}</label>
          </div>
        </div>
      </div>
      <hr />
      <div class="col-lg-12">
        <p class="text-muted">Dados de localização</p>
        <div class="row">
          <div class="col-lg-2">
            <div class="row">
              <div class="col-lg-8">
                <label for="" class="text-muted">CEP</label><br />
                <label for="">{{
                  formatCep(detalheUnidadeProdutiva.cep)
                }}</label>
              </div>
              <div class="col-lg-3">
                <label for="" class="text-muted">UF</label><br />
                <label for="">{{ detalheUnidadeProdutiva.uf }}</label>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-3">
                <label for="" class="text-muted">Município</label><br />
                <label for="">{{ detalheUnidadeProdutiva.municipio }}</label>
              </div>
              <div class="col-lg-6">
                <label for="" class="text-muted">Endereço</label><br />
                <label for="">{{ detalheUnidadeProdutiva.endereco }}</label>
              </div>
              <div class="col-lg-3">
                <label for="" class="text-muted">Bairro</label><br />
                <label for="">{{ detalheUnidadeProdutiva.bairro }}</label>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="row">
              <div class="col-sm-6">
                <label for="" class="text-muted">Latitude</label><br />
                <label for="">{{ detalheUnidadeProdutiva.latitude }}</label>
              </div>
              <div class="col-sm-6">
                <label for="" class="text-muted">Longitude</label><br />
                <label for="">{{ detalheUnidadeProdutiva.longitude }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="col-lg-12">
        <p class="text-muted">Dados do Produtor/Grupo de Produtores</p>
        <div class="row">
          <div class="col-lg-2">
            <label for="" class="text-muted">Tipo</label><br />
            <label for="">{{ detalheUnidadeProdutiva.tipo }}</label>
          </div>
          <div class="col-lg-3">
            <label for="" class="text-muted">Produtor/Grupo</label><br />
            <label for="">{{ detalheUnidadeProdutiva.produtorGrupo }}</label>
          </div>
        </div>
      </div>
      <hr />
      <div class="col-lg-12">
        <p class="text-muted">Dados de área</p>
        <div class="row">
          <div class="col-lg-2">
            <label for="" class="text-muted">Área Total(Ha)</label><br />
            <label for="">{{
              detalheUnidadeProdutiva.areaTotal | number | replaceNumber
            }}</label>
          </div>
          <div class="col-lg-2">
            <label for="" class="text-muted">Área de Algodão(Ha)</label><br />
            <label for="">{{
              detalheUnidadeProdutiva.areaAlgodao | number | replaceNumber
            }}</label>
          </div>
          <div class="col-lg-3">
            <label for="" class="text-muted"
              >Área de Proteção Ambiental(Ha)</label
            ><br />
            <label for="">{{
              detalheUnidadeProdutiva.areaProtecao | number | replaceNumber
            }}</label>
          </div>
        </div>
      </div>
      <hr />
      <h5><strong>Matrículas Cadastradas</strong></h5>
      <div *ngIf="detalheUnidadeProdutiva">
        <div
          class="col-lg-12"
          *ngFor="let m of detalheUnidadeProdutiva.matriculas; let idx = index"
        >
          <hr />
          <div class="row align-start-responsive">
            <div class="col-lg-1">
              <a
                ><strong>{{ idx + 1 }}</strong></a
              >
            </div>
            <div class="col-lg-1">
              <label for="" class="text-muted">Matrícula</label><br />
              <label for=""
                ><strong>{{ m.numeroMatricula }}</strong></label
              >
            </div>
            <div class="col-lg-1">
              <label for="" class="text-muted">Tipo</label><br />
              <label for=""
                ><strong>{{ m.tipo }}</strong></label
              >
            </div>
            <div class="col-lg-1">
              <label for="" class="text-muted">UF</label><br />
              <label for=""
                ><strong>{{ m.uf }}</strong></label
              >
            </div>
            <div class="col-lg-1">
              <label for="" class="text-muted">Município</label><br />
              <label for=""
                ><strong>{{ m.cidade }}</strong></label
              >
            </div>
            <div class="col-lg-5">
              <label for="" class="text-muted">Cartório</label><br />
              <label for=""
                ><strong>{{ m.nomeCartorio }}</strong></label
              >
            </div>
            <div class="col-lg-2">
              <label for="" class="text-muted">Área Total(Ha)</label><br />
              <label for=""
                ><strong>{{
                  m.areatotal | number | replaceNumber
                }}</strong></label
              >
            </div>
          </div>
          <div
            class="row mt-2 align-end-responsive"
            *ngFor="
              let produtor of m.unidadeProdutivaProdutorMatricula;
              let i = index
            "
          >
            <ng-container *ngIf="!!produtor">
              <div class="col-lg-1">
                <a
                  ><strong>{{ idx + 1 + "." + i + 1 }}</strong></a
                >
              </div>
              <div class="col-lg-1">
                <label for="" class="text-muted">Tipo</label><br />
                <label for=""
                  ><strong>{{ produtor.tipoProdutorGrupo }}</strong></label
                >
              </div>
              <div class="col-lg-2">
                <label for="" class="text-muted">Produtor/Grupo</label><br />
                <label for=""
                  ><strong>{{ produtor.nomeProdutorGrupo }}</strong></label
                >
              </div>
              <div class="col-lg-2">
                <label for="" class="text-muted">CPF/CNPJ</label><br />
                <label for=""
                  ><strong>{{ produtor.cpfcnpj }}</strong></label
                >
              </div>
              <div class="col-lg-2">
                <label for="" class="text-muted">Inscrição Estadual</label
                ><br />
                <label for=""
                  ><strong>{{ produtor.inscricaoEstadual }}</strong></label
                >
              </div>
              <div class="col-lg-2">
                <label for="" class="text-muted">Área(Ha)</label><br />
                <label for=""
                  ><strong>{{ produtor.areaProdutorGrupo }}</strong></label
                >
              </div>
            </ng-container>
          </div>
        </div>
        <hr />
        <h6 class="mt-2 mb-2"><strong>Dados de Algodoeira(s)</strong></h6>
        <p class="text-muted mt-2 mb-0">Beneficiamento</p>
        <p>
          {{
            detalheUnidadeProdutiva?.beneficiamento | beneficiamentoAlgodoeira
          }}
        </p>
        <div *ngIf="detalheUnidadeProdutiva.unidadeProdutivaAlgodoeiras">
          <div
            *ngFor="
              let u of detalheUnidadeProdutiva.unidadeProdutivaAlgodoeiras;
              let i = index
            "
          >
            <hr class="mb-1 mt-1" />
            <div class="row">
              <div class="col-lg-1">
                <p for="">
                  <strong>{{ i + 1 }}</strong>
                </p>
              </div>
              <div class="col-lg-1">
                <label for="" class="text-muted">ID</label><br />
                <label for=""
                  ><strong>{{ u.algodoeiraid }}</strong></label
                >
              </div>
              <div *ngIf="u.possuiAlgodoeira" class="col-lg-5">
                <label for="" class="text-muted">Nome fantasia</label><br />
                <label for=""
                  ><strong>{{ u.nomeFantasia }}</strong></label
                >
              </div>
              <div *ngIf="u.possuiAlgodoeira" class="col-lg-2">
                <label for="" class="text-muted">Código GS1</label><br />
                <label for=""
                  ><strong>{{ u.codigoGS1 }}</strong></label
                >
              </div>
              <div *ngIf="u.possuiAlgodoeira" class="col-lg-3">
                <label for="" class="text-muted">Dígito de Extensão</label
                ><br />
                <label for=""
                  ><strong>{{ u.digitoExtensao }}</strong></label
                >
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            detalheUnidadeProdutiva?.unidadeProdutivaAlgodoeiras.length === 0
          "
        >
          <p>
            <strong>
              Não existe(m) algodoeira(s) vinculada(s) a esta Unidade Produtiva
            </strong>
          </p>
        </div>
      </div>
    </div>
    <div class="modal-footer footer-content-responsive">
      <button type="button" class="btn btn-light" (click)="modal.close()">
        Fechar
      </button>
    </div>
  </div>
</ng-template>
