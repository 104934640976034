<modal-simples [isBtn]="true" [btnDisabled]="!arquivo" [labelAtivador]="'Upload da Licença BCI'"
  (confirmar)="fazerUpload($event)">

  <form [formGroup]="uploadForm">
    <h2 class="modal-title mb-5">Upload da Licença BCI</h2>
    <p class="text-muted mb-0">Insira o arquivo PDF da Licença BCI</p>
    <input class="mb-5" type="file" accept="application/pdf" name="licencaBci" (change)="handleFileInput($event)" />

  </form>
</modal-simples>
