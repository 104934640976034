import { delay } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { AppService } from "./app-service";
import { Services } from "./service";
import { Injectable } from "@angular/core";
import { Status } from "../entities/status";
import { EtapaABR } from "../entities/etapas";
import { UnidadeProdutiva } from "../entities/unidade-produtiva";
import { VdpService } from "./vdp.service";

@Injectable({
  providedIn: "root",
})
export class BciService {
  constructor(private vdpService: VdpService, private appService: AppService, private services: Services) {}

  downloadLicencaBci(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    const safra = this.appService.getSafraSelecionada();
    return this.services.get(`UpCertificacao/${unidadeProdutiva.id}/${safra.id}/downloadCertificadoBci`);
  }

  verificaDownloadLicencaBci(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    const safra = this.appService.getSafraSelecionada();
    return this.services.get(`UpCertificacao/${unidadeProdutiva.id}/${safra.id}/verificaExistenciaCertificadoBci`);
  }

}
