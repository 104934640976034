import { UnidadeProdutiva } from './../../entities/unidade-produtiva';
import { Component, Input, OnInit } from '@angular/core';
import { Services } from 'src/app/services/service';

@Component({
  selector: 'app-acesso-convites',
  templateUrl: './acesso-convites.component.html',
  styleUrls: ['./acesso-convites.component.scss']
})
export class AcessoConvitesComponent implements OnInit {

  @Input() unidadeProdutiva: UnidadeProdutiva;

  constructor(private service: Services) { }

  ngOnInit(): void {
  }

  acessarConvites() {
    this.service.redirectConvites();
  }

}
