import { AppService } from './app-service';
import { delay } from "rxjs/operators";
import { AndamentoCertificacao } from "./../entities/andamento-certificacao";
import { EtapaABR } from "./../entities/etapas";
import { createStatus, Status, StatusConvite, StatusConviteFormatado, StatusPcnc } from "./../entities/status";
import { Observable, of } from "rxjs";
import { Services } from "./service";
import { Injectable } from "@angular/core";
import {
  SetoresAndamentoCertificacao,
} from "../entities/andamento-certificacao";
import { PerfilUsuario } from "../entities/perfil";
import { PermissoesService } from "./permissoes.service";
import { Setor } from "../entities/setor";
import { VcpService } from './vcp.service';
import { VdpService } from './vdp.service';
import { PcncService } from './pcnc.service';
import { UnidadeProdutiva } from '../entities/unidade-produtiva';
import { ConviteService } from './convite.service';
import { FinalizandoCertificacaoService } from './finalizando-certificacao.service';

@Injectable({
  providedIn: "root",
})
export class AndamentoCertificacaoService {
  constructor(
    private service: Services,
    private permissoesService: PermissoesService,
    private appService: AppService,
    private vcpService: VcpService,
    private vdpService: VdpService,
    private pcncService: PcncService,
    private conviteService: ConviteService,
    private finalizandoCertificacaoService: FinalizandoCertificacaoService
  ) {}

  getStatusConvite(unidadeProdutiva: UnidadeProdutiva): Observable<StatusConviteFormatado> {
    return this.conviteService.getStatusSafraSelecionada(unidadeProdutiva);
  }

  getStatusVcp(unidadeProdutiva: UnidadeProdutiva): Observable<Status> {
    return this.vcpService.getStatusSafraSelecionada(unidadeProdutiva);
  }

  getStatusVdp(unidadeProdutiva: UnidadeProdutiva): Observable<Status> {
    return this.vdpService.getStatusSafraSelecionada(unidadeProdutiva);
  }

  getStatusPcnc(unidadeProdutiva: UnidadeProdutiva): Observable<StatusPcnc> {
    return this.pcncService.getStatusSafraSelecionada(unidadeProdutiva);
  }

  getAndamentoFinalizacaoAbr(
    unidadeProdutiva: UnidadeProdutiva
  ): Observable<AndamentoCertificacao> {
    return this.finalizandoCertificacaoService.getStatusABRSafraSelecionada(unidadeProdutiva);
  }

  getAndamentoLicencaBci(
    unidadeProdutiva: UnidadeProdutiva
  ): Observable<AndamentoCertificacao> {
    return this.finalizandoCertificacaoService.getStatusBCISafraSelecionada(unidadeProdutiva);
  }

  podeVerSetor(
    perfil: PerfilUsuario,
    setor: SetoresAndamentoCertificacao
  ): boolean {
    const setoresPermitidos: SetoresAndamentoCertificacao[] = this.permissoesService.getPermissoes(
      perfil,
      Setor.ANDAMENTO_CERTIFICACAO
    ) as SetoresAndamentoCertificacao[];

    return setoresPermitidos.some((setorPermitido) => setorPermitido === setor);
  }
}
