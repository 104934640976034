import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit, Input } from '@angular/core';
import { Services } from '../../services/service';
import { NotaCertificacao } from 'src/app/entities/NotaCertificacao';
import { UnidadeProdutiva } from 'src/app/entities/unidade-produtiva';
import { HistoricoCertificacao } from 'src/app/entities/historicoCertificacao';
import { Safra } from 'src/app/entities/safra';


@Component({
    selector: "modal-historico-abr",
    templateUrl: "./modal-historico-abr.component.html",
    styleUrls: ['./modal-historico-abr.component.scss']
})
export class ModalhistoricoAbr implements OnInit {

    constructor(private modalService: NgbModal, private services: Services) { }

    @Input() unidadeProdutiva: UnidadeProdutiva;
    @Input() notaCertificacao: NotaCertificacao;
    @Input() historicoCertificacao: HistoricoCertificacao[];
    usuarioLogado: any;
    safraEscolhida: Safra;
    listaSafras = null;


    notaAtingida: any;

    ngOnInit(): void {
        this.services.getAll('Safra').subscribe(safras => {
            this.listaSafras = (safras as any)
        })
        this.safraEscolhida = JSON.parse(window.localStorage.getItem('safraEscolhida'));
    }

    buscarHistoricoCertificacao(safra){
        this.safraEscolhida = safra;
        this.services.get('UpCertificacao/listastatus/'+ this.unidadeProdutiva.id + "/" + this.safraEscolhida.id ).subscribe(historico => {
          this.historicoCertificacao = historico;
      });
    }

    open(content) {
        this.safraEscolhida;
        this.services.get('UpCertificacao/listastatus/'+ this.unidadeProdutiva.id + "/" + this.safraEscolhida.id ).subscribe(historico => {
            this.historicoCertificacao = historico;
        });
        this.modalService.open(content, { size: 'xl', centered: true })
    }
}
