<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ msg }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal"
            (click)="onClose()">{{ cancelTxt }}</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm()">{{ okTxt }}</button>
    </div>
</div>