export class SafraCertificacao {
  upId: number;
  safraCertificacao: number;
  unidadeProdutivaId: number;
  nomeUsuario: string;
  cpfUsuario: string;

}

export interface SafraParaCertificacao {
  codigoSafra: number;
  descricao: string;
}
