import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  DoCheck,
  HostListener,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalService } from "src/app/services/modal.service";

import { Safra } from "../../entities/safra";
import { UnidadeProdutiva } from "../../entities/unidade-produtiva";
import { AppService } from "../../services/app-service";
import { Step } from "../../shared/multi-steps/multi-steps.component";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-modal-container",
  templateUrl: "./modal-container.component.html",
  styleUrls: ["./modal-container.component.scss"],
})
export class ModalContainerComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() steps: Step[];
  @Input() esconderAndamentoCertificacao: boolean = false;
  @Input() loading = false;

  @Output() onUpdate = new EventEmitter();
  @Output() changeStep = new EventEmitter<Step>();
  @Output() showAndamentoCertificacaoClick = new EventEmitter();

  @Output() toggleModalStatus = new EventEmitter<boolean>();

  safraCertificacao: number;

  safra: Safra;
  activeStepLabel: string;

  showAndamentoCertificacao = false;

  modalAberto = false;
  iconInfo = faInfoCircle;

  constructor(
    private modalService: NgbModal,
    private appService: AppService,
    private appModalService: ModalService
  ) {}

  ngOnInit(): void {}

  private __inicializar() {
    this.appModalService
      .getDefaultActiveStepLabel()
      .subscribe((novoValor) => (this.activeStepLabel = novoValor));

    this.safraCertificacao = this.unidadeProdutiva.safraCertificacao;

    this.appModalService
      .getSafraCertificacao()
      .subscribe((novoValor) => (this.safraCertificacao = novoValor));
  }

  open(content) {
    this.loading = true;

    this.__inicializar();

    window.localStorage.setItem(
      "unidadeProdutiva",
      JSON.stringify(this.unidadeProdutiva)
    );

    this.safra = this.appService.getSafraSelecionada();

    this.setModalAberto(true);

    this.modalService.open(content, {
      size: "xl",
      centered: true,
      beforeDismiss: () => this.beforeClose(),
    });
  }

  setModalAberto(novoValor: boolean) {
    this.modalAberto = novoValor;
    this.toggleModalStatus.emit(this.modalAberto);
    this.loading = false;
  }

  beforeClose() {
    this.setModalAberto(false);
    return true;
  }

  onShowAndamentoCertificacao() {
    this.showAndamentoCertificacao = !this.showAndamentoCertificacao;
    this.activeStepLabel = undefined;

    this.showAndamentoCertificacaoClick.emit();
  }

  onClickStep(step: Step) {
    this.showAndamentoCertificacao = false;
    this.activeStepLabel = step.label;

    this.changeStep.emit(step);
  }
  update() {
    this.onUpdate.emit();
  }
}
