<app-modal-container
  (changeStep)="onChangeStep($event)"
  [steps]="steps"
  (onUpdate)="onUpdate()"
  [unidadeProdutiva]="unidadeProdutiva"
  [esconderAndamentoCertificacao]="esconderAndamentoCertificacao"
  (toggleModalStatus)="modalAberto = $event"
  [loading]="loading"
>
  <modal-etapa-gestao-abrapa
    *ngIf="modalAberto"
    [unidadeProdutiva]="unidadeProdutiva"
  >
  </modal-etapa-gestao-abrapa>
</app-modal-container>
