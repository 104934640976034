import jwt_decode from "jwt-decode";

export class JwtToken {
  token: string;
  expires: number;
  type: string = 'Bearer ';

  constructor() {
    this.token = localStorage.getItem("token");
  }

  setToken(token: string, expires: number, type: string) {
    localStorage.setItem('usuario', JSON.stringify(this.getDecodedToken(token)));
    localStorage.setItem('token', token);

    this.token = token;
    this.expires = expires;
    this.type = type;
  }

  getDecodedToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }

  hasToken = () => this.token !== undefined;

  authorization() {
    return this.hasToken() ? `${this.type} ${this.token}` : undefined;
  }

  limpar() {
    this.token = undefined;
    this.expires = 0;
    this.type = undefined;
  }
}
