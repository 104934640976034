import { TermoConvite } from './../entities/api-convites';
import { createStatus, Status, StatusConvite, StatusConviteFormatado } from "src/app/entities/status";
import { Observable } from "rxjs";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { Services } from "./service";
import { Injectable } from "@angular/core";
import { AppService } from "./app-service";
import { Safra } from "../entities/safra";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ConviteService {
  constructor(private service: Services, private appService: AppService) { }

  getStatus(unidadeProdutiva: UnidadeProdutiva, safra: Safra): Observable<StatusConviteFormatado> {
    return this._formatarConvite(
      this.service.get(`Convite/${unidadeProdutiva.id}/${safra.id}/status`)
    );
  }

  getStatusSafraSelecionada(unidadeProdutiva: UnidadeProdutiva): Observable<StatusConviteFormatado> {
    const safra = this.appService.getSafraSelecionada();

    return this.getStatus(unidadeProdutiva, safra);
  }

  private _formatarConvite(statusConvite$: Observable<StatusConvite>): Observable<StatusConviteFormatado> {
    return statusConvite$.pipe(
      map((res: StatusConvite) => ({
        abr: createStatus(res.statusAbr, res.dataAbr, res.usuarioAbr, false),
        bci: createStatus(res.statusBci, res.dataBci, res.usuarioBci, false)
      }))
    );
  }

  getTermoDeAdesao(unidadeProdutiva: UnidadeProdutiva): Observable<TermoConvite> {
    const safra = this.appService.getSafraSelecionada();
    const tipo = unidadeProdutiva.tipo === "Grupo" ? "g" : "p";
    return this.service.getConvites(`convites/${unidadeProdutiva.idProdutorGrupo}/${tipo}/${unidadeProdutiva.id}/termos/?safra_id=${safra.id}`);

  }
}
