<div class="row main-content-responsive">
  <div class="col-lg-4">
    <app-labeled-button label="Formulário final ABR">
      <!-- <app-external-link-btn [isBtn]="false" button (action)="acessarDadosCertificado()">
        Preencher dados do Certificado.
      </app-external-link-btn> -->
      <modal-emitir-numero-certificacao
        (certificadoDefinido)="carregarCertificacoes()"
        [unidadeProdutiva]="unidadeProdutiva"
        button
      >
      </modal-emitir-numero-certificacao>
      <div observation>
        *Após o preenchimento completo a Unidade Produtiva será automaticamente
        considerada como
        <strong>Certificada</strong>
      </div>
    </app-labeled-button>
    <div class="mt-2">
      <app-labeled-button label="Gerar Certificado ABR (PDF)">
        <button
          [disabled]="!numeroCertificadoValido"
          (click)="downloadCertificadoAbr()"
          button
        >
          <fa-icon [icon]="pdfIcon" size="sm" class="mr-2"></fa-icon>
          Download do Certificado ABR
        </button>
        <div observation>
          *O certificado PDF será gerado com os dados de certificação mais
          atualizados
        </div>
      </app-labeled-button>
    </div>
  </div>

  <div class="col-lg-4 status-content-responsive">
    <app-status-convite title="Status" [status]="status"></app-status-convite>
    <dropdown-btn labelAtivadora="Outras opções">
      <dropdown-btn-item *ngIf="podeSolicitarRetorno">
        <modal-retorno
          [etapa]="'emissão do Certificado'"
          [etapaAtual]="etapaAtual"
          [unidadeProdutiva]="unidadeProdutiva"
        ></modal-retorno>
      </dropdown-btn-item>
    </dropdown-btn>
  </div>

  <div class="col-lg-4 certification-content-responsive">
    <div class="col-lg-6">
      <app-title-number
        *ngIf="certificacaoABR"
        title="Certificado ABR"
        [number]="certificacaoABR.numero || 'Não identificado'"
      >
      </app-title-number>
    </div>
    <div class="col-lg-6">
      <app-title-number
        *ngIf="certificacaoBCI$ | async as certificacaoBCI"
        title="Licença BCI"
        [number]="certificacaoBCI.numero || 'Não identificado'"
      >
      </app-title-number>
    </div>
  </div>
</div>
<div class="button-content">
  <modal-cancelar-certificacao
    [unidadeProdutiva]="unidadeProdutiva"
    (CarregarDadosModal)="carregarCertificacoes()"
    (atualizacaoRealizada)="atualizar()"
    [podeVerBotaoCancelarCertAbr]="podeVerBotaoCancelarCertAbr"
  ></modal-cancelar-certificacao>

  <span [ngbTooltip]="tooltipConfirmar" placement="top">
    <button
      [disabled]="!verificarCertificacaoABR"
      class="btn btn-sm btn-success m-1"
      (click)="onModalEtapaCertificadoAbr()"
    >
      Concluir a Emissão do Certificado ABR
    </button>
  </span>
</div>

<basic-modal
  #modalEtapaCertificadoAbr
  [modalConfig]="modalEtapaCertificadoAbrConfig"
>
  <div>
    <p>Você confirma que deseja Concluir a Emissão do Certificado ABR?</p>
  </div>
</basic-modal>
