<spinner [visible]="spinnerVisibility"></spinner>
<a href="#" class="btn btn-primary btn-sm col-md-8" role="button" aria-pressed="true" (click)="open(content)">Convidar
    Usuários</a>

<ng-template #content let-modal>
    <spinner [visible]="spinnerVisibility"></spinner>
    <div class="col-lg-12">
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <h6>Convidar Usuários - Fazenda do Backend</h6><br>
                        <input class="form-control form-control-sm col-lg-12" type="text"
                        [(ngModel)]="produtor"
                        [ngbTypeahead]="search"
                        [inputFormatter]="formatter"
                        [resultFormatter]="formatter"
                        [editable]='false' 
                        (selectItem)="convidarSinda($event)"
                        placeholder="Busque pelo CPF, nome ou e-mail do usuário" />
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-lg-12">
                    <button type="button" (click)="novoConvite()" class="btn btn-primary">Novo Usuário</button>
                </div>
            </div>
            <br>
            <div class="row" *ngIf="convidar || produtor !== undefined">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">{{ produtor !== undefined ? 'Usuário selecionado' : 'Novo usuário - Responsável ABR' }}</h6><br>
                            <form [formGroup]="formConvite">
                                <div class="form-group">
                                    <label for="nomeCompleto" class="col-form-label col-form-label-sm">Nome completo*</label>
                                    <input class="form-control form-control-sm col-lg-12" [ngClass]="{'is-invalid': formConvite.get('nome').errors && formConvite.get('nome').touched}" type="text" id="nomeCompleto" formControlName="nome"/>
                                    <div id="campoObrigatorio" class="invalid-feedback">
                                        Campo obrigatório.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="cpf" class="col-form-label col-form-label-sm">CPF*</label>
                                    <input [textMask]="cpfMask" class="form-control form-control-sm col-lg-12" [ngClass]="{'is-invalid': formConvite.get('cpf').errors && formConvite.get('cpf').touched}" type="text" id="cpf" formControlName="cpf"/>
                                    <div id="campoObrigatorio" class="invalid-feedback">
                                        Campo obrigatório.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="email" class="col-form-label col-form-label-sm">E-mail*</label>
                                    <input class="form-control form-control-sm col-lg-12" [ngClass]="{'is-invalid': formConvite.get('email').errors && formConvite.get('email').touched}" type="email" id="email" formControlName="email"/>
                                    <div id="campoObrigatorio" class="invalid-feedback">
                                        Campo obrigatório.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="telefoneFixo" class="col-form-label col-form-label-sm">Telefone
                                        fixo*</label>
                                    <input [textMask]="telephoneMask" class="form-control form-control-sm col-lg-12" type="text" [ngClass]="{'is-invalid': formConvite.get('telefoneFixo').errors && formConvite.get('telefoneFixo').touched}"
                                        id="telefoneFixo" formControlName="telefoneFixo"/>
                                    <div id="campoObrigatorio" class="invalid-feedback">
                                        Campo obrigatório.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="celular" class="col-form-label col-form-label-sm">Celular*</label>
                                    <input [textMask]="mobileMask" class="form-control form-control-sm col-lg-12" [ngClass]="{'is-invalid': formConvite.get('celular').errors && formConvite.get('celular').touched}" type="text" id="celular" formControlName="celular"/>
                                    <div id="campoObrigatorio" class="invalid-feedback">
                                        Campo obrigatório.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input hidden class="form-control form-control-sm col-lg-12" type="text" id="unidadeProdutivaCertificacaoId" formControlName="unidadeProdutivaCertificacaoId"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="limparConvite();modal.close()">Cancelar</button>
            <button type="submit" class="btn btn-primary" [disabled]="!formConvite.valid" (click)="onSubmit()">Enviar Convite</button>
        </div>
    </div>
</ng-template>