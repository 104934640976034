<titulo-modal>VDP</titulo-modal>

<div *ngIf="!loading; else shimmer">
  <div class="mb-3 content-responsive">
    <app-status-dot [status]="containerNota.statusNota"></app-status-dot>
    <button class="btn btn-sm btn-link" (click)="open(content)">
      Notas VDP
    </button>
  </div>
</div>

<ng-template #content let-modal>
  <modal-etapa-vdp-nota
    [containerNota]="containerNota"
    [unidadeProdutiva]="unidadeProdutiva"
    [modal]="modal"
  ></modal-etapa-vdp-nota>
</ng-template>

<ng-template #shimmer>
  <div class="d-flex flex-column">
    <shimmer width="160px" height="17px" class="mb-1"></shimmer>
    <shimmer width="90px"></shimmer>
    <shimmer width="120px" height="20px"></shimmer>
  </div>
</ng-template>
