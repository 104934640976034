import { EtapaABR, toEtapaAbr } from "./etapas";

export interface Status {
  etapa: string;
  etapaId: EtapaABR;
  nome: string;
  dataInclusao: Date;
  usuarioCpf?: string;
  pulouEtapa: boolean;
}
export interface StatusPcnc {
  etapa: string;
  etapaId: EtapaABR;
  nome: string;
  dataInclusao: Date;
  usuarioCpf?: string;
  pulouEtapa: boolean;
  ausenteNaoConformidade: boolean;
}

export interface StatusConvite {
  statusAbr: string;
  usuarioAbr: string;
  dataAbr: string;
  statusBci: string;
  usuarioBci: string;
  dataBci: string;
  id: number;
}

export interface StatusConviteFormatado {
  abr: Status,
  bci: Status
}

export const createStatus = (etapa: string, data: string, usuarioCpf: string, pulouEtapa: boolean) : Status => ({
  etapa,
  etapaId: toEtapaAbr(etapa),
  dataInclusao: new Date(data),
  usuarioCpf,
  nome: usuarioCpf ? usuarioCpf : "Não identificado",
  pulouEtapa
});

export const createStatusPcnc = (etapa: string, data: string, usuarioCpf: string, pulouEtapa: boolean, ausenteNaoConformidade: boolean) : StatusPcnc => ({
  etapa,
  etapaId: toEtapaAbr(etapa),
  dataInclusao: new Date(data),
  usuarioCpf,
  nome: usuarioCpf ? usuarioCpf : "Não identificado",
  pulouEtapa,
  ausenteNaoConformidade
});

