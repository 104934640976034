export class UsuarioConvidado {
  id: number
  nome: string
  cpf: string
  email: string
  telefoneFixo: string
  celular: string
  isSinda: boolean
  dataInclusao: Date
  dataExclusao: Date
  unidadeProdutivaCertificacaoId: number
}
export interface Usuario {
  unique_name: string;
  UsuarioId: string;
  NomeUsuario: string;
  email: string;
  sub: string;
  jti: string;
  iat: number;
  role: string[];
  app: string[];
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
}
