
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Status } from "../../entities/status";
import { UnidadeProdutiva } from "../../entities/unidade-produtiva";
import { Step } from "../../shared/multi-steps/multi-steps.component";
import { BciService } from "src/app/services/bci.service";
import { VdpService } from 'src/app/services/vdp.service';
import { ModalEtapaLicencaBciComponent } from '../modal-etapa-licenca-bci/modal-etapa-licenca-bci.component';

@Component({
  selector: 'modal-licenca-bci',
  templateUrl: './modal-licenca-bci.component.html',
  styleUrls: ['./modal-licenca-bci.component.scss']
})
export class ModalLicencaBciComponent implements OnInit,AfterViewInit {

  @Input() unidadeProdutiva: UnidadeProdutiva;

  @Input() esconderAndamentoCertificacao: boolean = true;
  @Output() etapaTrocada = new EventEmitter();
  @ViewChild(ModalEtapaLicencaBciComponent) modalGestao:ModalEtapaLicencaBciComponent;


  status: Status;
  steps: Step[];
  defaultActiveStepLabel: string;
  activeStepLabel: string;

  showAndamentoCertificacao = false;

  modalAberto = false;

  loading = true;

  constructor(private vdpService: VdpService) { }

  ngOnInit(): void {
    this._defineSteps();
  }

  ngAfterViewInit() {
    this.modalGestao?._carregarDado();
  }

  private _defineSteps() {
    this.steps = [
      {
        label: "Licença BCI",
        active: true,
      },
    ];
    this.defaultActiveStepLabel = "Licença BCI";
    this.activeStepLabel = this.defaultActiveStepLabel;

    this.loading = false;
  }

  onChangeStep(step: Step) {
    this.activeStepLabel = step.label;
  }

  onShowAndamentoCertificacao() {
    this.showAndamentoCertificacao = !this.showAndamentoCertificacao;
  }
  onUpdate(){
    this.modalGestao._carregarDado();
  }
}

