<footer class="sticky-footer">
  <div class="container">
    <div class="text-center d-flex copyright row color-text">
      <div class="col-lg-3">
        <img
          src="../assets/images/logo ABRAPA.png"
          class="footer-image image-fluid"
          alt="Associação
        Brasileira dos Produtores de Algodão"
        />
      </div>
      <div class="col-lg-6">
        &copy; {{ anoAtual }} - ABRAPA - Todos os Direitos Reservados
        <br /><strong>Associação Brasileira dos Produtores de Algodão</strong>
        <br />
        Setor de Indústrias Bernardo Sayão (SIBS) Quadra 1, Conjunto B, Lote 2,
        Edifício Abrapa, 1º andar CEP 71736-102 – Núcleo Bandeirante,
        Brasília-DF
        <br />
        <strong>Site - </strong
        ><a href="https://suporte.abrapa.com.br" target="_blank"
          >suporte.abrapa.com.br
        </a>
        <strong>E-mail - </strong> atendimento.sistemas&#64;abrapa.com.br
      </div>
      <div class="col-lg-3">
        <img
          src="../assets/images/iba_logo.svg"
          class="footer-image-iba image-fluid"
          alt="Instituto Brasileiro do Algodão"
        />
        <div>
          O ABR é financiado com recursos do Instituto Brasileiro do Algodão
        </div>
      </div>
    </div>
  </div>
</footer>
