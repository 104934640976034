import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private subscriptions: Subscription[] = [];

  constructor() { }

  add(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  get(): Subscription[] {
    return this.subscriptions;
  }

  unsubscribeAll() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe);
  }
}
