import { HistoricoCertificacao } from "./../entities/historicoCertificacao";
import { Observable, of } from "rxjs";

import { Injectable } from "@angular/core";

import { ApiResponse } from "../entities/api-response";
import { EtapaABR } from "../entities/etapas";
import { ContainerNota, MetaNota, Nota } from "../entities/NotaCertificacao";
import { createStatus } from "../entities/status";
import { AppService } from "./app-service";
import { Services } from "./service";
import { delay, map } from "rxjs/operators";
import { UnidadeProdutiva } from "../entities/unidade-produtiva";
import { Safra } from "../entities/safra";
import { IFormularioService } from "./IFormulatio";
import { NotaService } from "./nota.service";

@Injectable({
  providedIn: "root",
})
export class VdpService implements IFormularioService {
  constructor(
    public service: Services,
    private appService: AppService,
    private notaService: NotaService
  ) {}

  getStatus(unidadeProdutiva: UnidadeProdutiva, safraSelecionada: Safra) {
    const { id } = this.appService.getSafraSelecionada();
    return this.appService.formatarStatus(
      this.service.get(`EtapaVdp/${unidadeProdutiva.id}/${id}/status`)
    );
  }

  getStatusSafraSelecionada(unindadeProdutiva: UnidadeProdutiva) {
    return this.getStatus(
      unindadeProdutiva,
      this.appService.getSafraSelecionada()
    );
  }

  concluirEtapaVDP(unidadeProdutiva: UnidadeProdutiva) {
    return this.service.patch(`EtapaVdp/${unidadeProdutiva.id}/concluirVdp`);
  }

  reenviarEmail(unidadeProdutiva: UnidadeProdutiva): Observable<{ message: string }> {
    return this.service.patch(`EtapaPcnc/${unidadeProdutiva.id}/reenviaEmailPcnc`);
  }

  reenviarEmailVDP(unidadeProdutiva: UnidadeProdutiva): Observable<{ message: string }> {
    const safra = this.appService.getSafraSelecionada();
    return this.service.post(`EtapaVdp/${unidadeProdutiva.id}/${safra.id}/ReenviarEmail`,"");
  }

  pularEtapaVdp(unidadeProdutiva: UnidadeProdutiva) {
    return this.service.patch(`EtapaVdp/${unidadeProdutiva.id}/pularEtapaVdp`);
  }

  encerrarVdp(unidadeProdutiva: UnidadeProdutiva) {
    return this.service.patch(`EtapaVdp/${unidadeProdutiva.id}/encerrarVdp`);
  }

  buscarFormulario(unidadeProdutiva: UnidadeProdutiva) {
    const safra = this.appService.getSafraSelecionada();
    return this.service.patch(`EtapaVdp/${unidadeProdutiva.id}/${safra.id}/buscarFormulario`);
  }

  /**
   * Obtém as notas de uma unidade produtiva na safra selecionada globalmente.
   *
   * @param unidadadeProdutivaId
   */
  getNotas(
    unidadadeProdutiva: UnidadeProdutiva,
    safra: Safra
  ): Observable<ContainerNota> {
    return this.notaService.formatarNotas(
      this.service.get(`EtapaVdp/${unidadadeProdutiva.id}/${safra.id}/buscarNotaCertificacaoPorSafraPorUp`)
    );
  }

  getNotasSafraSelecionada(
    unidadeProdutiva: UnidadeProdutiva
  ) : Observable<ContainerNota> {
    const safra = this.appService.getSafraSelecionada();
    return this.getNotas(unidadeProdutiva, safra);
  }
}
