<app-modal-container
  (changeStep)="onChangeStep($event)"
  [steps]="steps"
  (onUpdate) = "onUpdate()"
  (showAndamentoCertificacaoClick)="onShowAndamentoCertificacao()"
  [unidadeProdutiva]="unidadeProdutiva"
  [esconderAndamentoCertificacao]="esconderAndamentoCertificacao"
  (toggleModalStatus)="modalAberto = $event"
  [loading]="loading"
  >
    <app-modal-etapa-licenca-bci
      *ngIf="modalAberto"
      [unidadeProdutiva]="unidadeProdutiva"
    >
    </app-modal-etapa-licenca-bci>
</app-modal-container>
