<ng-container *ngIf="isBtn; else elseLink">
  <button [disabled]="disabled" class="btn btn-sm btn-primary">
    <fa-icon [icon]="icon" size="sm" class="mr-2"></fa-icon>
    <ng-container *ngTemplateOutlet="myContent"></ng-container>
  </button>
</ng-container>

<ng-template #elseLink>
  <div class="external-link-responsive" *ngIf="!disabled">
    <a href="#" (click)="actionPerform()" class="d-block mb-2 mt-2 mr-2">
      <fa-icon [icon]="icon" size="sm" class="mr-2"></fa-icon>
      <ng-container *ngTemplateOutlet="myContent"></ng-container>
    </a>
  </div>
  <div class="external-link-responsive" *ngIf="disabled">
    <span href="#" class="d-block mb-2 mt-2 mr-2 btn-link text-muted">
      <fa-icon [icon]="icon" size="sm" class="mr-2"></fa-icon>
      <ng-container *ngTemplateOutlet="myContent"></ng-container>
    </span>
  </div>
</ng-template>

<ng-template #myContent>
  <ng-content></ng-content>
</ng-template>
