import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'beneficiamentoAlgodoeira'
})
export class BeneficiamentoAlgodoeiraPipe implements PipeTransform {

  transform(value: string): string {

    if (value === 'P') {
      return 'Próprio';
    }

    if (value === 'T') {
      return 'Terceiros';
    }

    if (value === 'PT') {
      return 'Próprio + Terceiros';
    }


    return 'Não definido';
  }

}
