import { ModalAndamentoCertificao } from './../modal/modal-andamento-certificacao/modal-andamento-certificacao.component';
import { Pipe, PipeTransform } from '@angular/core';
import { SetoresAndamentoCertificacao } from '../entities/andamento-certificacao';

@Pipe({
  name: 'podeVerSetorAndamentoPipe'
})
export class PodeVerSetorAndamentoPipe implements PipeTransform {

  constructor(
    private modalAndamento: ModalAndamentoCertificao
    ) { }


  transform(value: SetoresAndamentoCertificacao, ...args: unknown[]): boolean {
    return this.modalAndamento.podeVerSetor(value);
  }

}
