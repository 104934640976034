// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api: 'https://hom.api.novoabr.abrapa.coopersystem.com.br',
  redirecionamento_externo: 'https://sistemas.coopersystem.com.br',
  sentryDSN: "https://621c6833865a4f82abc39ad2814ffc4c@sentry.coopersystem.com.br/14",
  api_convites: 'https://hom.api.convites.abrapa.coopersystem.com.br/api/v1',
  convites: 'https://hom.convites.abrapa.coopersystem.com.br/',
  dataLimiteRespostaConvite: "2023-05-31",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
