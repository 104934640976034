<modal-simples
  [isBtn]="true"
  [labelAtivador]="'Definir limite BCI'"
  [titulo]="'Definir data limite para aceite no BCI'"
  (confirmar)="definirLimiteBci(dataLimiteBciInput, $event)"
  (openEvent)="getDataLimiteBci()"
>
  <div class="d-flex flex-column">
    <label>Data limite para aceite no BCI</label>
    <div style="max-width: 250px; padding: 10px;">
      <input #dataLimiteBciInput class="form-control" type="date" [(ngModel)]="dateTimeValue"  />
    </div>
  </div>
</modal-simples>
