import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'ativador-tooltip',
  templateUrl: './ativador-tooltip.component.html',
  styleUrls: ['./ativador-tooltip.component.scss']
})
export class AtivadorTooltipComponent implements OnInit {

  @Input() disabled = false;
  @Input() label : string;
  @Input() isBtn = false;
  @Input() tooltipEnabled = false;
  @Input() tooltipText = "";
  @Input() isPrimary = true;
  @Input() placement = "right";

  btnPrimary = "btn-primary";
  btnSuccess = "btn-success";

  @Output() action = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  actionPerform() {
    this.action.emit();
  }

  get tipoBtn(): string{
    return this.isPrimary ? this.btnPrimary : this.btnSuccess;
  }

}
