<titulo-modal>{{title}}</titulo-modal>
<ng-container *ngIf="!loading; else shimmer">
  <app-unidade-produtiva-status *ngFor="let s of status" [status]="s"></app-unidade-produtiva-status>
</ng-container>
<ng-template  #shimmer>
  <div *ngFor="let item of shimmers" class="d-flex flex-column mb-3">
    <shimmer width="80%" height="18px"></shimmer>
    <shimmer width="70%"></shimmer>
  </div>
</ng-template>
