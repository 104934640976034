import { Component, Input, OnInit } from "@angular/core";
import { EtapaABR } from "../../entities/etapas";

const grayClass = "dot-gray";
const redClass = "dot-red";
const greenClass = "dot-green";
const yellowClass = "dot-orange";

const etapaABRColorsMapping = {
  [EtapaABR.ABR_CERTIFICADO_NAO_OPTANTE_BCI.toLowerCase()]: greenClass,
  [EtapaABR.ABR_CERTIFICADA.toLowerCase()]: greenClass,
  [EtapaABR.NAO_INICIADO.toLowerCase()]: grayClass,
  [EtapaABR.NAO_CONVIDADA.toLowerCase()]: grayClass,
  [EtapaABR.BCI_LICENCIADA.toLowerCase()]: greenClass,
  [EtapaABR.CONVIDADA.toLowerCase()]: yellowClass,
  [EtapaABR.CONVITE_ACEITO_ABR.toLowerCase()]: greenClass,
  [EtapaABR.CONVITE_ACEITO_BCI.toLowerCase()]: greenClass,
  [EtapaABR.CONVITE_ACEITO.toLowerCase()]: greenClass,
  [EtapaABR.CONVITE_RECUSADO.toLowerCase()]: redClass,
  [EtapaABR.CONVITE_RECUSADO_BCI.toLowerCase()]: redClass,
  [EtapaABR.CONVITE_REALIZADO_ABR.toLowerCase()]: yellowClass,
  [EtapaABR.CONVITE_REALIZADO_BCI.toLowerCase()]: yellowClass,
  [EtapaABR.CONVITE_RECUSADO_HOME.toLowerCase()]: redClass,
  [EtapaABR.VDP_CONCLUIDA.toLowerCase()]: greenClass,
  [EtapaABR.VDP_ANDAMENTO.toLowerCase()]: yellowClass,
  [EtapaABR.PCNC_AUSENTE_DE_NAO_CONFORMIDADES.toLowerCase()]: greenClass,
  [EtapaABR.PCNC_CONCLUIDO.toLowerCase()]: yellowClass,
  [EtapaABR.PCNC_RECUSOU_VCP.toLowerCase()]: redClass,
  [EtapaABR.PCNC_ACEITOU_VCP.toLowerCase()]: greenClass,
  [EtapaABR.VCP_EM_ANDAMENTO_INCOMPLETA.toLowerCase()]: yellowClass,
  [EtapaABR.VCP_EM_ANDAMENTO_COMPLETA_NAO_CONCLUIDA.toLowerCase()]: yellowClass,
  [EtapaABR.VCP_REPROVADA.toLowerCase()]: redClass,
  [EtapaABR.VCP_CONCLUIDA.toLowerCase()]: yellowClass,
  [EtapaABR.VCP_APROVADA.toLowerCase()]: greenClass,
  [EtapaABR.CERTIFICACAO_EM_EMISSAO.toLowerCase()]: yellowClass,
  [EtapaABR.CERTIFICADA_ABR_NAO_OPTANTE_BCI.toLowerCase()]: greenClass,
  [EtapaABR.CERTIFICADA_ABR_EM_ANDAMENTO_BCI.toLowerCase()]: greenClass,
  [EtapaABR.CERTIFICADA_ABR_LICENCIADO_BCI.toLowerCase()]: greenClass,
  [EtapaABR.CERTIFICADA_ABR_REPROVADO_BCI.toLowerCase()]: greenClass,
  [EtapaABR.NAO_OPTANTE_BCI.toLowerCase()]: redClass,

  //Reuso para status do convites
  [EtapaABR.ACEITO.toLowerCase()]: greenClass,

};

@Component({
  selector: "app-etapa-abr-dashboard-td",
  templateUrl: "./etapa-abr-dashboard-td.component.html",
})
export class EtapaAbrDashboardTdComponent implements OnInit {
  @Input() etapa: string;
  dotClass: string;

  constructor() { }

  ngOnInit(): void {
    this.dotClass = etapaABRColorsMapping[this.etapa.toLowerCase()] ?? yellowClass;
  }
}
