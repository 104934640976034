import { Component, Input, OnInit, DoCheck, Output, EventEmitter, OnDestroy, ViewChild } from "@angular/core";
import { finalize } from "rxjs/operators";

import { HistoricoCertificacao } from "../entities/historicoCertificacao";
import { Safra } from "../entities/safra";
import { Status } from "../entities/status";
import { UnidadeProdutiva } from "../entities/unidade-produtiva";
import { ModalService } from "../services/modal.service";
import { PcncService } from "../services/pcnc.service";
import { SubscriptionService } from "../services/subscription.service";
import { VdpService } from "../services/vdp.service";
import { Step } from "../shared/multi-steps/multi-steps.component";

@Component({
  selector: "modal-convite-estadual",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, DoCheck, OnDestroy {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() historicoCertificacao: HistoricoCertificacao;
  @Input() safra: Safra;
  @Input() esconderAndamentoCertificacao: boolean = false;
  @Input() safraId: number;
  @Output() etapaTrocada = new EventEmitter();

  @ViewChild("modalConvite") modalConvite;
  @ViewChild("modalEtapaVDP") modalEtapaVDP;
  @ViewChild("modalAceiteConvite") modalAceiteConvite;
  @ViewChild("modalEtapaPCNC") modalEtapaPCNC;

  convidado: boolean = false;
  etapaVdp: boolean = false;
  etapaPcnc: boolean = false;
  naoConvidado: boolean = false;
  safraEscolhida: Safra;

  modalAberto = false;

  defaultActiveStepLabel: string;
  activeStepLabel: string;

  showAndamentoCertificacao = false;

  etapa: string;

  statusVdp: Status;
  statusPcnc: Status;

  loading = false;

  loadingVdp = true;
  loadingPcnc = true;
  steps: Step[] = [];

  constructor(
    private modalService: ModalService,
    private vdpService: VdpService,
    private pcncService: PcncService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscriptionService.unsubscribeAll();
  }

  private construirSteps() {
    this.steps = [
      {
        label: "Convite",
        active: true,
      },
      {
        label: "VDP",
        active: this.podeVerEtapaVDP(),
      },
      {
        label: "PCNC",
        active: this.podeVerEtapaPcnc(),
      },
    ];
  }

  private _carregarStep() {
    const step$ = this.modalService.getDefaultActiveStepLabel()
      .subscribe(
        novoValor => {
          this.activeStepLabel = novoValor;
          this.construirSteps();
        });
    this.subscriptionService.add(step$);
  }

  ngDoCheck(): void {
    this.loading = this.loadingPcnc && this.loadingVdp;
  }

  podeVerEtapaVDP() {
    
    return this.unidadeProdutiva.etapaAbr.toUpperCase().includes("VDP") || 
    this.unidadeProdutiva.etapaAbr.toUpperCase().includes("PCNC")||
    this.unidadeProdutiva.etapaAbr.toUpperCase().includes("VCP")||
    this.unidadeProdutiva.etapaAbr.toUpperCase().includes("CERTIFICA")||
    this.unidadeProdutiva.etapaAbr.toUpperCase().includes("RETORNO DE ETAPA")
  }

  podeVerEtapaPcnc() {
    return this.unidadeProdutiva.etapaAbr.toUpperCase().includes("PCNC")||
    this.unidadeProdutiva.etapaAbr.toUpperCase().includes("VCP")||
    this.unidadeProdutiva.etapaAbr.toUpperCase().includes("CERTIFICA")||
    this.unidadeProdutiva.etapaAbr.toUpperCase().includes("RETORNO DE ETAPA")
  }

   _carregarModal() {
    
    this._carregarStatusPcnc();
    this._carregarStatusVdp();

     if (this.modalConvite)
       this.modalConvite.loading();

     if (this.modalEtapaVDP)
       this.modalEtapaVDP.loading();

     if (this.modalAceiteConvite)
       this.modalAceiteConvite.loading();

     if (this.modalEtapaPCNC)
       this.modalEtapaPCNC.loading();
  }

  private _carregarStatusPcnc() {
    this.loadingPcnc = true;
    const pcnc$ = this.pcncService.getStatusSafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingPcnc = false)
      )
      .subscribe(status => {
        this.statusPcnc = status;
        this._defineDefaultActiveStepAndlabel();
      });
    this.subscriptionService.add(pcnc$);
  }

  private _carregarStatusVdp() {
    this.loadingVdp = true;
    const vdp$ = this.vdpService.getStatusSafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => this.loadingVdp = false)
      )
      .subscribe(status => {
        this.statusVdp = status;
        this._defineDefaultActiveStepAndlabel();
      });
    this.subscriptionService.add(vdp$);
  }

  private _defineDefaultActiveStep() {
    if (this.podeVerEtapaPcnc()) {
      return this._changeDefaultActiveStep("PCNC");
    }

    if (this.podeVerEtapaVDP()) {
      return this._changeDefaultActiveStep("VDP");
    }

    this._changeDefaultActiveStep("Convite");
  }

  private _changeDefaultActiveStep(novoValor) {
    this.modalService.changeDefaultActiveStepLabel(novoValor);
  }

  private _defineDefaultActiveStepAndlabel() {
    this._defineDefaultActiveStep();
  }

  private _inicializar() {
    this._carregarModal();
    this._carregarStep();
  }

  onChangeStep(step: Step) {
    this.activeStepLabel = step.label;
  }

  onConcluiuEtapa() {
    this._carregarModal();
    this._carregarStep();
    this.etapaTrocada.emit();
  }

  toggleModalStatus(statusModal: boolean) {
    this.modalAberto = statusModal;
    if (this.modalAberto) {
      this._inicializar();
    }
  }
}
