import { ModalHome } from './../entities/modal-home';
import { Pipe, PipeTransform } from '@angular/core';
import { HomeComponent } from '../home/home.component';

@Pipe({
  name: 'podeVerSetorModalPipe'
})
export class PodeVerSetorModalPipe implements PipeTransform {


  constructor(
    private homeComponent: HomeComponent
    ) { }

  transform(value: ModalHome, ...args: unknown[]): boolean {
    return this.homeComponent.podeVerModal(value);
  }

}
