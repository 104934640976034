import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-nota-necessaria-obtida",
  templateUrl: "./nota-necessaria-obtida.component.html",
  styleUrls: ["./nota-necessaria-obtida.component.scss"],
})
export class NotaNecessariaObtidaComponent implements OnInit {
  @Input() title: string;
  @Input() necessaria: number;
  @Input() obtida: number;

  constructor() {}

  ngOnInit(): void {}
}
