<button *ngIf="habilitarCancelarCertificacao" [disabled]="!podeVerBotaoCancelarCertAbr" class="btn btn-sm btn-dark m-1" role="button" aria-pressed="true" (click)="open(content)">
    Cancelar Certificação
</button>
<br>

<ng-template #content let-modal>
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit(); modal.close()">
    <div class="modal-body">
        <br>
        <div>
          <p class="h6">
            <strong>Você tem certeza que deseja <span class="text-danger">Cancelar a Certificação</span> desta Unidade Produtiva? Esta ação não poderá
              ser desfeita</strong>
          </p>
        </div>
        <div class="">
          <p class="text-muted">Unidade Produtiva</p>
          <p>{{unidadeProdutiva.nomeUnidadeProdutiva | titlecase}}</p>
        </div>
            <div>
                <div class="col-lg-12">
                    <label for="motivo" class="col-form-label col-form-label-sm text-muted">Motivo*</label>
                    <textarea class="form-control" id="motivo" formControlName="motivo"></textarea>
                </div>
            </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-md" (click)="modal.close()">Desistir</button>
        <button type="submit" class="btn btn-primary btn-md">Confirmar Cancelamento</button>
    </div>
</form>
</ng-template>
