<ng-template *ngIf="authorized; else unauthorized">
</ng-template>

<ng-template #unauthorized>
  <div class="text-center" *ngIf="processoFinalizado">
    <h3>
      <span style="color: red">A sessão atual expirou. Faça o login novamente.</span> <br />
      Link de acesso atualizado: <a href="{{redirecionamentoExterno}}">{{redirecionamentoExterno}}</a>
    </h3>
  </div>
</ng-template>
