import { APP_API } from './../app.api';
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ToastService } from "./toast.service";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { SpinnerService } from "./spinner.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  [x: string]: any;

  constructor(
    private toast: ToastService,
    private router: Router,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<import("@angular/common/http").HttpEvent<any>> {
    req = this.authService.addAuthenticationToken(req);

    return next.handle(req).pipe(
      catchError((error) => {
        this.spinnerService.hide();
        if (error.status === 0) {
          this.toast.show("O servidor está inacessível no momento.", {
            classname: `bg-danger text-light`,
            delay: 5000,
            autohide: true,
            headertext: `Server Error`,
          });
          return throwError(error.statusText);
        }
        if (error.status === 401 && req.url.includes(APP_API)) {
          let message = "";
          if (this.router.routerState.snapshot.url === "/login") {
            message = "As credenciais informadas são válidas";
          } else {
            message = "Você não possui permissão para acessar esse conteúdo.";
          }

          this.authService.logoutWithError();

          return throwError(error.statusText);
        }
        if (error.status === 403 && req.url.includes(APP_API)) {
          let message = "";
          if (this.router.routerState.snapshot.url === "/login") {
            message = "Você não possui permissão para acessar esse conteúdo.";
          } else {
            message = "Você não possui permissão para acessar esse conteúdo.";
          }

          this.authService.logoutWithError();

          return throwError(error.statusText);
        }
        if (error instanceof HttpErrorResponse) {
          const applicationError = error.headers.get("Application-Error");
          if (applicationError) {
            return throwError(applicationError);
          }
          const serverError = error.error || error.error.detail;

          let modalStateErrors = "";
          if (serverError?.error && typeof serverError?.error === "object") {
            for (const key in serverError.error) {
              if (serverError.error[key]) {
                modalStateErrors += serverError.error[key] + "\n";
              }
            }
          }
          this.toast.show(modalStateErrors || serverError.detail || serverError || "Server Error", {
            classname: `bg-danger text-light`,
            delay: 5000,
            autohide: true,
            headertext: `Error`,
          });
        }
      })
    );
  }
}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
