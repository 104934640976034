<div class="modal-header">
  <h4 class="modal-title">Definição de Certificadora Responsável</h4>
  <!-- <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body">
  <div class="alert alert-info" role="alert">
    <fa-icon [icon]="iconInfo" size="sm"></fa-icon>&nbsp; Somente usuários com o
    perfil da certificadora selecionada irão ter acesso ao registro para
    aplicação da VCP.
  </div>
  <select
    id="certificadora"
    class="form-control form-control-sm"
    name="certificadora"
    [(ngModel)]="certSelecionada"
  >
    <option selected *ngIf="certificadoras.length > 1" [value]="null">Selecione...</option>
    <option
      *ngFor="let certificadora of certificadoras"
      [value]="certificadora.idCertificadora"
    >
      {{ certificadora.nome }}
    </option>
  </select>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="salvar()">
    Salvar
  </button>
</div>
