<a href="#" class="btn btn-primary btn-sm" role="button" aria-pressed="true" (click)="open(content)">
  Editar Safra da Certificação
</a>


<br>

<ng-template #content let-modal>
  <div class="modal-body">
    <div class="col-lg-12">
      <p class="h4"><strong>
          Editar Safra da Certificação
        </strong></p>
    </div>
    <br>
    <form [formGroup]="formSafraCertificacao">
      <div class="form-group">
        <div class="col-lg-5">
          <label for="safraCertificacaoUp" class="col-form-label col-form-label-sm">
            <span class="text-muted">Safra de Certificação</span>

          </label>
          <select (change)="onOptionsSelected(safraCertificacao)" #safraCertificacao class="form-control"
            id="safraCertificacaoUp" style="width: fit-content;">
            <option selected disabled value="">Selecione</option>
            <option *ngFor="let op of safras" [value]="op">{{op}}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light btn-md" (click)="modal.close()">Cancelar</button>
    <button type="submit" [disabled]="!safraSelecionada" class="btn btn-primary btn-md"
      (click)="onSubmit(modal,safraCertificacao)">Salvar</button>
  </div>
</ng-template>
