import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ContainerNota } from "./../../entities/NotaCertificacao";
import { UnidadeProdutiva } from "./../../entities/unidade-produtiva";
import { Component, Input } from "@angular/core";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-modal-notas",
  templateUrl: "./modal-notas.component.html",
  styleUrls: ["./modal-notas.component.scss"],
})
export class ModalNotasComponent {
  @Input() title: string;
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() modal: any;

  icon = faCircle;

  constructor(private modalService: NgbModal) {}

  @Input() containerNota: ContainerNota;

  getNotaPorSafra(unidadeProdutiva: UnidadeProdutiva): number {
    let notaMinima = 90;

    if (unidadeProdutiva.safraCertificacao === 1) {
      notaMinima = 85;
    }

    if (unidadeProdutiva.safraCertificacao === 2) {
      notaMinima = 87;
    }

    if (unidadeProdutiva.safraCertificacao === 3) {
      notaMinima = 89;
    }

    return notaMinima;
  }

  onClose() {
    this.modal.close();
  }
}
