<ng-container *ngIf="!tooltipEnabled; else withTooltip">
  <button
    [disabled]="disabled"
    *ngIf="isBtn; else elseLink"
    (click)="actionPerform()"
    class="btn btn-sm"
    [ngClass]="tipoBtn"
  >
    {{ label }}
  </button>

  <ng-template #elseLink>
    <div class="activator-tooltip-responsive" *ngIf="!disabled">
      <a href="#" (click)="actionPerform()" class="d-block mb-2 mt-2 mr-2"
        >{{ label }}
      </a>
    </div>
    <div class="activator-tooltip-responsive" *ngIf="disabled">
      <span class="d-block mb-2 mt-2 mr-2 btn-link text-muted"
        >{{ label }}
      </span>
    </div>
  </ng-template>
</ng-container>

<ng-template #withTooltip>
  <div
    class="d-inline activator-tooltip-responsive"
    *ngIf="isBtn; else elseLink"
    [placement]="placement"
    [ngbTooltip]="tooltipText"
  >
    <button
      [disabled]="disabled"
      (click)="actionPerform()"
      class="btn btn-sm"
      [ngClass]="tipoBtn"
    >
      {{ label }}
    </button>
  </div>

  <ng-template #elseLink>
    <div class="activator-tooltip-responsive" *ngIf="!disabled">
      <a
        href="#"
        (click)="actionPerform()"
        placement="right"
        [ngbTooltip]="tooltipText"
        >{{ label }}</a
      >
    </div>
    <div class="activator-tooltip-responsive" *ngIf="disabled">
      <span class="d-block mb-2 mt-2 mr-2 btn-link text-muted">{{
        label
      }}</span>
    </div>
  </ng-template>
</ng-template>
