import { Observable } from "rxjs";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { Etapas } from "./../../entities/etapas";
import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Status } from "src/app/entities/status";
import { SolicitarRetornoEtapaService } from "src/app/services/solicitar-retono-etapa.service";
import { StatusSolicitacaoDotColorEnum } from "../modal-retorno/status-solicitacao-dot-color-enum";
import { SpinnerService } from "src/app/services/spinner.service";

@Component({
  selector: "modal-gerenciar-etapa",
  templateUrl: "./modal-gerenciar-etapa.component.html",
  styleUrls: ["./modal-gerenciar-etapa.component.scss"],
})
export class ModalGerenciarEtapaComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() status: Status;
  @Input() etapas$: Observable<Etapas[]>;
  listaSolicitacoes: any[];

  constructor(
    private modalService: NgbModal,
    private solicitacaoServices: SolicitarRetornoEtapaService,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {}

  open(content) {
    this.modalService.open(content, { centered: true });
    this._buscarEtapas();
  }

  _buscarEtapas() {
    this.spinner.show();
    this.solicitacaoServices
      .getListaSolicitacao(
        this.unidadeProdutiva.id,
        this.unidadeProdutiva.codigoSafra
      )
      .subscribe(
        (data) => {
          this.listaSolicitacoes = data;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  buscarCorEtapa(status: string) {
    return StatusSolicitacaoDotColorEnum[status];
  }
}
