<ng-container *ngIf="status">
  <div class="content-responsive">
    <h5 class="mb-0">
      <app-etapa-abr-dashboard-td
        [etapa]="status.etapa"
      ></app-etapa-abr-dashboard-td>
    </h5>
    <ng-container *ngIf="!emEtapaSemAtor">
      <p>
        por <b>{{ status.nome }}</b> no dia
        <b>{{ status.dataInclusao | date : "dd/MM/yyyy" }}</b>
      </p>
    </ng-container>
  </div>
</ng-container>
