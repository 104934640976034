import { ToastService } from './../../../services/toast.service';
import { NotaExplicativaService } from './../../../nota-explicativa.service';
import { UnidadeProdutiva } from './../../../entities/unidade-produtiva';
import { Component, Input, OnInit } from '@angular/core';
import { ToastType } from 'src/app/entities/toast';

@Component({
  selector: 'app-modal-vdp-nota-explicativa-preenchida',
  templateUrl: './modal-vdp-nota-explicativa-preenchida.component.html',
  styleUrls: ['./modal-vdp-nota-explicativa-preenchida.component.scss']
})
export class ModalVdpNotaExplicativaPreenchidaComponent implements OnInit {

  @Input() unidadeProdutiva: UnidadeProdutiva
  @Input() modal: any;

  constructor(private service: NotaExplicativaService, private toastService: ToastService) { }

  ngOnInit(): void {
  }

  downloadNotaExplicativa() {

    this.service.gerarNotaExplicativa(this.unidadeProdutiva).subscribe((res: any) => {
      if (!res.conteudo) {
        this.toastService.showToast(ToastType.DANGER, "Falha", "Certificado não disponível");
        return;
      }

      const data = 'data:application/pdf;base64,' + res.conteudo;
      const link = document.createElement("a");
      const fileName = res.nomeArquivo;

      link.href = data;
      link.download = fileName;
      link.click();
    }
    );
  }

  close() {
    this.modal.close()
  }

}
