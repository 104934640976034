import { SetoresAndamentoCertificacao } from "./andamento-certificacao";
import { ModalHome } from "./modal-home";
import { SetoresHome } from "./setores-home";

export enum Setor {
  HOME = "home",
  MODAL_HOME = "modal_home",
  ANDAMENTO_CERTIFICACAO = "andamento_certificacao",
}

export interface SetoresSistema {
  home: SetoresHome[],
  modal_home: ModalHome,
  andamento_certificacao: SetoresAndamentoCertificacao[]
}
