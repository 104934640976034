<div class="row">
    <div class="col-lg-4">
        <h6>Você foi convidado para ser o Responsável ABR desta
            Unidade Produtiva</h6>
        <div class="row" *ngIf="!aceites?.statusAbr">
            <div class="col-lg-12" style="padding-bottom: 10px;">
                <button (click)="redirecionarAceiteAbr()" class="btn btn-primary">
                    <fa-icon [icon]="iconLink" size="md"></fa-icon> Responder Convite ABR
                </button>
            </div>
        </div>
        <div class="row" *ngIf="!aceites?.statusBci">
            <div class="col-lg-12">
                <button (click)="redirecionarAceiteBci()" class="btn btn-primary">
                    <fa-icon [icon]="iconLink" size="md"></fa-icon> Responder Convite BCI
                </button>
            </div>
        </div>
    </div>
    <div class="offset-lg-2">
        <titulo-modal>Status do convite</titulo-modal>
        <ng-container *ngFor="let s of status">
            <ng-container *ngIf="s.etapa !== null">
                <h5><span [ngClass]="{'dot-orange' : s?.etapa === 'Convite realizado',
                    'dot-green' : s?.etapa === 'ABR - Convite aceito' || s?.etapa === 'BCI - Convite aceito',
                    'dot-red' : s?.etapa === 'ABR - Convite recusado' || s?.etapa === 'BCI - Convite recusado',
                    'dot-gray' : s?.etapa === 'Não convidado'}" id="badge"></span>{{s?.etapa}}</h5>
                <p>por <b>{{s?.nome}}</b> no dia <b>{{s?.dataInclusao | date: 'dd/MM/yyyy'}}</b></p>
            </ng-container>
        </ng-container>
    </div>
</div>
