import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'multi-steps-abrapa',
  templateUrl: './multi-steps-abrapa.component.html',
  styleUrls: ['./multi-steps-abrapa.component.scss']
})
export class MultiStepsAbrapaComponent implements OnInit {

  @Input() andamento: boolean = false;
  @Input() gestao: boolean = false;

  constructor() { }

  ngOnInit(): void { }

}
