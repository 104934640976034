<nav id="navbar-top" class="navbar navbar-expand-md navbar-light bg-light mb-4">
  <div class="container">
    <a class="navbar-brand" href="/">
      <img
        src="../assets/images/Logo ABR.png"
        alt="ABRAPA-ABR"
        style="width: 210px"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarCollapse"
      aria-controls="navbarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul style="font-size: 125%" class="navbar-nav mr-auto">
        <li
          class="nav-item dropdown"
          [ngClass]="{ ' dropdown-responsive': width < 768 }"
        >
          <a
            style="color: #004f80"
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Safra {{ service.safraEscolhida?.descricaoSafra }}
          </a>

          <div
            class="dropdown-menu"
            [ngClass]="{ 'dropdown-menu-responsive': width < 768 }"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <div *ngFor="let m of service.listaSafras">
              <a
                class="dropdown-item"
                style="cursor: pointer"
                (click)="service.atualizarGrid(m.id)"
                >{{ m.descricaoSafra }}</a
              >
            </div>
          </div>
        </li>
        <li
          class="nav-item"
          [ngClass]="{ 'nav-title-responsive': width < 768 }"
        >
          <a
            style="color: #004f80"
            [ngbTooltip]="programaAbrTitleTootip"
            class="nav-link"
            href="#"
            >Programa ABR <span class="sr-only">(current)</span></a
          >
          <ng-template #programaAbrTitleTootip
            >Certificação de boas práticas sociais, ambientais e econômicas das
            Unidades Produtivas Brasileiras.</ng-template
          >
        </li>
      </ul>
      <ul class="navbar-nav navbar-nav-responsive">
        <li class="nav-item">
          <span class="nav-link text-dark">
            <i class="fas fa-user-circle"></i>
            Bem vindo <b>{{ service.nomeUsuario }}</b> -
            {{ service.loginUsuario }}
          </span>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngbTooltip]="sairTootip" href="#">
            <i class="fas fa-sign-out-alt"></i>
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-box-arrow-right sair"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
              />
              <path
                fill-rule="evenodd"
                d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
              /></svg
            >&nbsp;<span class="text-dark" (click)="service.logout()"
              >sair</span
            >
          </a>
          <ng-template #sairTootip>Sair do sistema ABR.</ng-template>
        </li>
      </ul>
    </div>
  </div>
</nav>
