<div class="row my-4 main-responsive">
  <div class="col-lg-3 mr-0 content-responsive">
    <div class="flex-column">
      <titulo-modal>Ações</titulo-modal>
      <modal-gerenciar-etapa
        [status]="status"
        [unidadeProdutiva]="unidadeProdutiva"
      ></modal-gerenciar-etapa>
      <modal-cancelar-certificacao
        [unidadeProdutiva]="unidadeProdutiva"
      ></modal-cancelar-certificacao>
    </div>
  </div>
  <div class="col-lg-5 m-0 content-responsive">
    <app-status-convite
      [status]="status"
      title="Status"
      [loading]="loadingStatus"
      [shimmerAmount]="1"
    >
    </app-status-convite>
  </div>
  <div class="col-auto col-lg-2">
    <div class="info-content-responsive">
      <app-exibir-vdp
        [loading]="loadingNotaVdp"
        [unidadeProdutiva]="unidadeProdutiva"
        [containerNota]="containerNotaVdp"
      >
      </app-exibir-vdp>
      <app-title-number
        [loading]="loadingCertificacaoABR"
        title="Certificado ABR"
        [number]="certificacaoABR || 'Não iniciado'"
      ></app-title-number>
    </div>
  </div>
  <div class="col-auto col-lg-2">
    <div class="info-content-responsive">
      <app-exibir-vcp
        [loading]="loadingNotaVcp"
        [unidadeProdutiva]="unidadeProdutiva"
        [containerNota]="containerNotaVcp"
      >
      </app-exibir-vcp>
      <app-title-number
        [loading]="loadingLicencaBCI"
        title="Licença BCI"
        [number]="licencaBCI || 'NÃO OPTANTE BCI'"
      ></app-title-number>
    </div>
  </div>
</div>

<div class="rpw user-content-responsive">
  <titulo-modal>Usuários Convidados</titulo-modal>
  <app-acesso-convites
    [unidadeProdutiva]="unidadeProdutiva"
  ></app-acesso-convites>
</div>
