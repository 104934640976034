import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AndamentoCertificacao } from '../entities/andamento-certificacao';
import { Safra } from '../entities/safra';
import { createStatus } from '../entities/status';
import { UnidadeProdutiva } from '../entities/unidade-produtiva';
import { AppService } from './app-service';
import { Services } from './service';

@Injectable({
  providedIn: 'root'
})
export class FinalizandoCertificacaoService {

  constructor(
    private service: Services,
    private appService: AppService
  ) { }

  getStatusABR(unidadeProdutiva: UnidadeProdutiva, safra: Safra): Observable<AndamentoCertificacao> {
    return this.formatarStatus(
      this.service.get(`FinalizandoCertificacao/${unidadeProdutiva.id}/${safra.id}/StatusABR`)
    );
  }

  getStatusBCI(unidadeProdutiva: UnidadeProdutiva, safra: Safra): Observable<AndamentoCertificacao> {
    return this.formatarStatus(
      this.service.get(`FinalizandoCertificacao/${unidadeProdutiva.id}/${safra.id}/StatusBCI`)
    );
  }


  simulaRespostaFormularios(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    return this.service.postWithParams(`FinalizandoCertificacao/${unidadeProdutiva.id}/simulaRespostaFormularios`, {});
  }


  respostaFormularioABR(unidadeProdutiva: UnidadeProdutiva, numeroCertificadoABR: string): Observable<any> {
    return this.service.patchWithParams(`FinalizandoCertificacao/${unidadeProdutiva.id}/respostaFormularioABR`, {
      'numeroCertificado': numeroCertificadoABR
    });
  }

  finalizaCertificacaoABR(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    return this.service.patch(`FinalizandoCertificacao/${unidadeProdutiva.id}/finalizaCertificacaoABR`);
  }

  respostaFormularioBCI(unidadeProdutiva: UnidadeProdutiva, numeroCertificadoBCI: string): Observable<any> {
    return this.service.patchWithParams(`FinalizandoCertificacao/${unidadeProdutiva.id}/respostaFormularioBCI`, {
      'numeroCertificado': numeroCertificadoBCI
    });
  }

  aprovaBCI(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    return this.service.patch(`FinalizandoCertificacao/${unidadeProdutiva.id}/aprovaBCI`);
  }

  reprovaBCI(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    return this.service.patch(`FinalizandoCertificacao/${unidadeProdutiva.id}/reprovaBCI`);
  }
  cancelaBCI(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    return this.service.patch(`FinalizandoCertificacao/${unidadeProdutiva.id}/cancelaBCI`);
  }

  getStatusABRSafraSelecionada(unidadeProdutiva: UnidadeProdutiva): Observable<AndamentoCertificacao> {
    const safra = this.appService.getSafraSelecionada();
    return this.getStatusABR(unidadeProdutiva, safra);
  }

  getStatusBCISafraSelecionada(unidadeProdutiva: UnidadeProdutiva): Observable<AndamentoCertificacao> {
    const safra = this.appService.getSafraSelecionada();
    return this.getStatusBCI(unidadeProdutiva, safra);
  }

  formatarStatus(res$: Observable<any>): Observable<AndamentoCertificacao> {
    return res$.pipe(
      map(res => this._createStatusFinalizacaoAbr(res))
    );
  }

  cancelarCertificacao(unidadeProdutiva: UnidadeProdutiva): Observable<any> {
    const safra = this.appService.getSafraSelecionada();
    const usuario = this.appService.getUsuario();
    return this.service.post(`CancelarCertificacao/cancelar`, 
    {"UnidadeProdutivaId": unidadeProdutiva.id,"SafraId":safra.id,"UsuarioCpf":usuario.unique_name,"Motivo":"cancelamento feito pelo sistema."});
  }

  private _createStatusFinalizacaoAbr(data: any): AndamentoCertificacao {
    return {
      status: createStatus(data.descricao, data.data, data.usuarioCpf, false),
      numeroCertificado: data.numeroCertificado,
      nomeCertificadora: data.nomeCertificadora
    }
  }
}
