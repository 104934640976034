<a href="#" class="btn btn-primary btn-sm btn-block" role="button" aria-pressed="true" (click)="open(content)">
    Definir data limite BCI
</a>
<br>

<ng-template #content let-modal>
    <div class="modal-body">
        <div class="col-lg-12">
            <titulo-modal>Definir data limite para aceite no BCI</titulo-modal>
        </div>
        <br>

        <form>
            <div class="form-group">
                <div class="col-lg-12 height: '1500px'">
                    <label for="dataLimite" class="col-form-label col-form-label-sm">Data limite para aceite no BCI</label>
                    <div class="input-group">
                        <input id="dataLimite" class="form-control" placeholder="dd-mm-yyyy" name="dp" ngbDatepicker #dp="ngbDatepicker">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light btn-md" (click)="modal.close()">Cancelar</button>
        <button type="button" class="btn btn-primary btn-md">Confirmar</button>
    </div>
</ng-template>
