import { ConviteService } from "./../../services/convite.service";
import { GestaoAbrapaService } from "./../../services/gestao-abrapa.service";
import { ToastService } from "./../../services/toast.service";
import { AlertModalService } from "src/app/services/alert-modal.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SpinnerService } from "./../../services/spinner.service";
import { Services } from "./../../services/service";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { EMPTY, Observable } from "rxjs";
import { finalize, switchMap, take } from "rxjs/operators";
import { BaseComponent } from "src/app/base/base.component";
import { HistoricoCertificacao } from "src/app/entities/historicoCertificacao";
import { Safra } from "src/app/entities/safra";
import { Status, createStatus } from "src/app/entities/status";
import { UnidadeProdutiva } from "src/app/entities/unidade-produtiva";
import { UsuarioConvidado } from "src/app/entities/usuario-convidado";
import { AlertModalComponent } from "src/app/shared/alert-modal/alert-modal.component";
import { ModalDefinirSafra } from "../modal-definir-safra/modal-definir-safra.component";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { ToastType } from "src/app/entities/toast";
import { EtapaABR } from "src/app/entities/etapas";

const statusInicial = {
  etapa: "",
  etapaId: null,
  nome: "",
  dataInclusao: null,
  pulouEtapa: false
};

@Component({
  selector: "modal-convite",
  templateUrl: "./modal-convite.component.html",
  styleUrls: ["./modal-convite.component.scss"],
})
export class ModalConviteComponent implements OnInit {
  @Input() unidadeProdutiva: UnidadeProdutiva;
  @Input() safra: Safra;
  @Input() safraId: number;

  loadingStatus = true;
  statusAbr: Status = {
    ...statusInicial
  };
  statusBci: Status = {
    ...statusInicial
  };

  etapaConvite: boolean = false;
  etapaVdp: boolean = false;
  etapaPcnc: boolean = false;
  etapaAndamento: boolean = false;
  safraDefinida = true;
  selecionado: UsuarioConvidado;

  exclamationIcon = faExclamationTriangle;

  loadingPrazoLimiteBci = true;
  prazoLimiteBci: Date;

  constructor(
    private service: Services,
    private spinnerService: SpinnerService,
    private gestaoAbrapaService: GestaoAbrapaService,
    private alertService: AlertModalService,
    private toastService: ToastService,
    private conviteService: ConviteService
  ) { }

  ngOnInit(): void {
    this.loading();
  }

  loading() {
    this._carregarDataLimiteConviteBci();
    this._carregarStatusConvite();
    this._carregarStatusSafraDefinnida();
  }

  private _carregarDataLimiteConviteBci() {
    this.loadingPrazoLimiteBci = true;

    this.gestaoAbrapaService
      .getDataLimiteEditarConviteBci()
      .pipe(
        finalize(() => {
          this.loadingPrazoLimiteBci = false;
        })
      )
      .subscribe(date => {
        if (date?.dataLimiteBci) {
          this.prazoLimiteBci = new Date(date?.dataLimiteBci);
        }
      });
  }

  private _carregarStatusConvite() {
    this.loadingStatus = true;
    this.conviteService
      .getStatusSafraSelecionada(this.unidadeProdutiva)
      .pipe(
        finalize(() => {
          this.loadingStatus = false;
        })
      ).subscribe((status) => {
        this.statusAbr = status.abr;
        this.statusBci = status.bci;
      });
  }

  private _carregarStatusSafraDefinnida() {
    this.safraDefinida =
      this.unidadeProdutiva.etapaAbr === EtapaABR.NAO_CONVIDADA ||
      this.unidadeProdutiva.etapaAbr === EtapaABR.NAO_INICIADO ||
      this.unidadeProdutiva.etapaAbr === EtapaABR.CONVIDADA ||
      this.unidadeProdutiva.etapaAbr === EtapaABR.CERTIFICACAO_CANCELADA ||
      this.unidadeProdutiva.etapaAbr === EtapaABR.VCP_REPROVADA ||
      this.unidadeProdutiva.etapaAbr === EtapaABR.PCNC_RECUSOU_VCP;
  }

  onSafraDefinida(event: any) {

    if (!!event?.safraCertificacao) {
      this.safraId = event.safraCertificacao;
      this.unidadeProdutiva.safraCertificacao = event.safraCertificacao;
    }

    this.loading();
  }
}
