<app-modal-container
  (changeStep)="onChangeStep($event)"
  [steps]="steps"
  (showAndamentoCertificacaoClick)="onShowAndamentoCertificacao()"
  [unidadeProdutiva]="unidadeProdutiva"
  (onUpdate)="_inicializar()"
  [esconderAndamentoCertificacao]="esconderAndamentoCertificacao"
  (toggleModalStatus)="toggleModalStatus($event)"
  [loading]="loading"
>
  <ng-container *ngIf="modalAberto && !loadingVCP && !loadingCertificacao">
    <modal-etapa-vcp
      *ngIf="activeStepLabel === 'VCP'"
      [status]="status"
      [unidadeProdutiva]="unidadeProdutiva"
      (concluirVCP)="onConcluirVCP()"
    >
    </modal-etapa-vcp>

    <app-modal-etapa-certificado-abr
      *ngIf="activeStepLabel === 'Certificado ABR'"
      [status]="statusCertificacao"
      (atualizacaoRealizada)="_inicializar()"
      [unidadeProdutiva]="unidadeProdutiva"
    >
    </app-modal-etapa-certificado-abr>
  </ng-container>
</app-modal-container>
