import { Injectable } from '@angular/core';
import { TipoFormulario } from '../entities/formulario';
import { IFormularioService } from './IFormulatio';
import { PcncService } from './pcnc.service';
import { VcpService } from './vcp.service';
import { VdpService } from './vdp.service';

@Injectable({
  providedIn: 'root'
})
export class CriadorFormularioService {

  map = {
    [TipoFormulario.VCP]: this.vcpService,
    [TipoFormulario.VDP]: this.vdpService,
    [TipoFormulario.PCNC]: this.pcncService
  }

  constructor(
    private vcpService : VcpService,
    private vdpService : VdpService,
    private pcncService : PcncService
  ) { }

  criarFormularioService(tipoFormulario : TipoFormulario) : IFormularioService {
    return this.map[tipoFormulario]
  }
}
