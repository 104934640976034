import { Component, Input } from "@angular/core";
import { UsuarioConvidado } from 'src/app/entities/usuario-convidado';
import { Validators } from '@angular/forms';
import { Helpers } from 'src/app/utils/helpers';
import { BaseComponent } from 'src/app/base/base.component';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ToastType } from "src/app/entities/toast";

@Component({
    selector: "modal-editar-dados",
    templateUrl: "./modal-editar-dados.component.html",
})
export class ModalEditarDados extends BaseComponent {

    @Input() usuarioConvidado: UsuarioConvidado;
    @Input() public cpfMask = { mask: Helpers.cpfMask, keepCharPositions: true};
    @Input() public telephoneMask = { mask: Helpers.telephoneMask, keepCharPositions: true};
    @Input() public mobileMask = { mask: Helpers.mobileMask, keepCharPositions: true};

    formConviteEditar = this.fb.group({
        id:[],
        nome: ['', Validators.required],
        cpf: [''],
        email: ['', Validators.compose([Validators.required, Validators.email])],
        telefoneFixo: ['', Validators.required],
        celular: ['', Validators.required],
        isSinda:[],
        dataExclusao:[],
        dataInclusao:[],
        unidadeProdutivaCertificacaoId: ['']
    });

    ngOnInit(): void {

    }

    open(content){
        this.modalService.open(content, {centered: true});
        this.formConviteEditar.setValue(this.usuarioConvidado);
    }

    editar(){
        let usuarioConvidado = new UsuarioConvidado();
        Object.assign(usuarioConvidado, this.formConviteEditar.value)

        var result$ = this.alertService.showConfirm();

        result$.asObservable()
            .pipe(
                switchMap(x => {
                    if(x){
                        this.showSpinner();
                        return this.service.put('Convite', usuarioConvidado, usuarioConvidado.id);
                    }
                    else {
                        this.hideSpinner;
                        return EMPTY;
                    }
                })
            ).subscribe(x => {
                this.hideSpinner();
                this.formConviteEditar.reset();
                this.showToast(ToastType.SUCCESS, 'Sucesso', x.message)
            });
    }
}
