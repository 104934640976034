<div class="row m-0 pt-4 mb-3" style="background-color: #f8f8f8">
  <div class="col-xl-3"></div>
  <div class="col-xl-6 row justify-content-center">
    <div class="col">
      <ul class="customed-progressbar pl-4">
        <li
          (click)="onClickStep(step)"
          *ngFor="let step of steps"
          [ngClass]="{
            active: step.active,
            'selected-step': activeStepLabel === step.label
          }"
        >
          <span class="btn-sm pointer">{{ step.label }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div
    class="col-xl-3 d-flex justify-content-xl-end p-0 justify-content-center"
  >
    <div *ngIf="!esconderAndamentoCertificacao" class="">
      <button
        (click)="onShowAndamentoCertificacaoClick()"
        class="btn btn-outline-primary btn-sm stepper-button-box"
      >
        Andamento da Certificação
      </button>
    </div>
  </div>
</div>
