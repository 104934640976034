<div class="row">
  <div class="col-lg-4">
    <app-status-convite [loading]="loadingStatusConvite" [shimmerAmount]="1" title="Status do Convite ABR"
      [status]="statusConviteAbr">
    </app-status-convite>
  </div>
  <div class="col-lg-4">
    <app-status-convite [loading]="loadingStatusConvite" [shimmerAmount]="1" title="Status do Convite BCI"
      [status]="statusConviteBci">
    </app-status-convite>

  </div>
  <div class="col-lg-4">
    <div>
      <modal-simples [disabled]="verificaStatusEditarBci" [tooltip]="naoPodeResponderConviteTooltip"
        (confirmar)="onConfirm($event)" [isBtn]="true"
        labelAtivador="Editar Convite BCI">
        <div class="mb-2">
          <span>
            <strong>Editar Convite BCI</strong>
          </span>
        </div>
        <div class="mb-1">
          <span>
            <strong>Adesão ao Licenciamento BCI </strong>
          </span>
        </div>

        <div class="form-check col text-muted">
          <div class="">
            <input class="form-check-input" type="radio" [(ngModel)]="aceitaParticipacao.aceiteBci" id="sim"
              [value]="true">
            <label class="form-check-label" for="sim">
              Sim
            </label>
          </div>
          <div>
            <input class="form-check-input" type="radio" [(ngModel)]="aceitaParticipacao.aceiteBci" id="nao"
              [value]="false">
            <label class="form-check-label" for="nao">
              Não
            </label>
          </div>
        </div>
        <small class="mt-1" *ngIf="podeResponderConviteBci">Após o dia <strong>{{ dataLimiteRespostaConviteBci |
            date:'dd/MM/yyyy'
            }}</strong> você não poderá mais editar o convite <strong>BCI</strong>.</small>
      </modal-simples>
      <div *ngIf="!!dataLimiteRespostaConviteBci" class="mt-1">
        <small *ngIf="podeResponderConviteBci">Após o dia <strong>{{ dataLimiteRespostaConviteBci | date:'dd/MM/yyyy'
            }}</strong> você não poderá mais editar o convite <strong>BCI</strong>.</small>
        <small *ngIf="!podeResponderConviteBci">O prazo para responder/editar o convite acabou dia&nbsp;<strong>{{
            dataLimiteRespostaConviteBci | date:'dd/MM/yyyy' }}</strong></small>
      </div>
    </div>
  </div>
</div>
