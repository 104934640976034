export enum SetoresHome {
  FORM_ID = "Campo id do formulario de busca",
  FORM_UNIDADE_PRODUTIVA= "Campo unidade_produtiva do formulario de busca",
  FORM_UF = "Campo UF do formulario de busca",
  FORM_MUNICIPIO = "Campo municipio do formulario de busca",
  FORM_TIPO = "Campo tipo do formulario de busca",
  FORM_PRODUTOR_GRUPO = "Campo produtor_grupo do formulario de busca",
  FORM_STATUS = "Campo status do formulario de busca",
  FORM_HABILITADO = "Campo habilitado do formulario de busca",
  FORM_PARTICIPANTE_BCI = "Campo participante_bci do formulario de busca",
  FORM_SAFRA_CERTIFICACAO = "Campo safra_certificacao do formulario de busca",
  FORM_ETAPA_ABR = "Campo etapa_abr no formulario de busca",
  BTN_DATA_LIMITE = "Botao para acionamento da modal data limite BCI",
  BTN_FORMULARIOS = "Botao para abrir os formularios do usuario",
  COLUNA_ID = "Coluna id da tabela com os resultados de busca",
  COLUNA_UNIDADE_PRODUTIVA = "Coluna unidade_produtiva da tabela com os resultados de busca",
  COLUNA_UF = "Coluna uf da tabela com os resultados de busca",
  COLUNA_MUNICIPIO = "Coluna municipio da tabela com os resultados de busca",
  COLUNA_TIPO = "Coluna tipo da tabela com os resultados de busca",
  COLUNA_PRODUTOR_GRUPO = "Coluna produtor_grupo da tabela com os resultados de busca",
  COLUNA_STATUS = "Coluna status da tabela com os resultados de busca",
  COLUNA_HABILITADO = "Coluna habilitado da tabela com os resultados de busca",
  COLUNA_SAFRA_CERTIFICACAO = "Coluna safra_certificacao da tabela com os resultados de busca",
  COLUNA_PARTICIPANTE_BCI = "Coluna participante_bci da tabela com os resultados de busca",
  COLUNA_ETAPA_ABR = "Coluna etapa_abr da tabela com os resultados de busca",
  COLUNA_DATA_ULTIMA_ATUALIZACAO = "da tabela com os resultados de busca",
}

